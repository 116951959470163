import React, { useState } from "react";
import useAxios from "../../hooks/useAxios";
import bankLogo from "../../Assets/bank.png";
import Accounts_category from "./Accounts_category";
import ReactMarkdown from "react-markdown";
import Heading from "../../Reuseable-Components/font/Heading";
import SubHeading from "../../Reuseable-Components/font/SubHeading";
import Paragraph from "../../Reuseable-Components/font/Paragraph";

const GET_ACCOUNTS = "api/checkratestypes?populate=*";

export default function Accounts() {
  const [accountData, setAccountData] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [activeCategoryTab, setActiveCategoryTab] = useState();
  const [categoryDescription, setCategoryDescription] = useState("");

  const activetab = "bg-[#0E381D] text-white text-sm 2xl:text-lg";
  const nonactivetab = "text-gray-600 font-semibold";

  const { data, error, loading } = useAxios(GET_ACCOUNTS);

  const AccountTypes = data?.filter(
    (accounttypes) =>
      accounttypes?.attributes?.check_rates_v_2?.data.attributes
        ?.checkrateid === "account"
  );

  const defaultData = AccountTypes?.filter(
    (accounttypes) => accounttypes?.id === 1
  );

  const changeTab = (id) => {
    setActiveTab(id);
    const accountdata = AccountTypes?.filter(
      (accounttypes) => accounttypes?.id === id
    );
    setActiveCategoryTab();
    setCategoryDescription("");
    setAccountData(accountdata);
  };
  if (loading)
    return (
      <div className="flex items-center justify-center text-green-500 my-10">
        <div
          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  if (error) return <p>Error :(</p>;

  return (
    <div className="container mx-auto  ">
      <div className="px-6 lg:px-0">
      <Heading classes={`my-12`}>Accounts</Heading>

      <div className=" flex flex-row gap-6 mb-6  mx-4">
        {AccountTypes.map((accountCategories) => (
          <div
            className={`font-sans px-3 py-1.5 lg:px-8 lg:py-3 rounded-3xl cursor-pointer shadow-lg  ${
              accountCategories.attributes.typesid === activeTab
                ? activetab
                : nonactivetab
            }`}
            onClick={() => changeTab(accountCategories.attributes.typesid)}
          >
            {accountCategories.attributes.typesname}
          </div>
        ))}
      </div>

      <div className="flex flex-col md:flex-row md:pl-6 md:gap-4 items-center shadow-lg mt-14">
        <img src={bankLogo} alt="image not found" className="w-28 md:w-36" />

        <div className="p-6 text-justify ">
          <SubHeading>{AccountTypes[0]?.attributes?.typesname}</SubHeading>
          <Paragraph classes={`pt-2`}>
            <ReactMarkdown>
              {AccountTypes[0]?.attributes?.description}
            </ReactMarkdown>
          </Paragraph>
        </div>
      </div>

      <Accounts_category
        accountData={accountData?.length > 0 ? accountData : defaultData}
        activeCategoryTab={activeCategoryTab}
        setActiveCategoryTab={setActiveCategoryTab}
        categoryDescription={categoryDescription}
        setCategoryDescription={setCategoryDescription}
      />
      </div>
    </div>
  );
}
