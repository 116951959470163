import React from "react";
import { useState } from "react";
import Heading from "../../Reuseable-Components/font/Heading";

import ATMs from "./ATMs";
import Branches from "./Branches";

const FindATMBranches = () => {
  const [selectedTab, setSelectedTab] = useState(true);
  return (
    <div className="container mx-auto font-sans">
      <div className="lg:mx-0  mx-10">
        {/* <h1 className="text-center my-10 font-medium text-2xl lg:text-5xl text-[#12381f]">
          Find ATMs / Branches
        </h1> */}
        <Heading classes={`my-10`}> Find ATMs / Branches</Heading>
        <button
          className={
            selectedTab
              ? `text-sm md:text-lg mt-6 px-6 py-2 rounded bg-green-600 text-white min-w-44`
              : ` text-[#12381f] text-sm md:text-lg bg-white mt-6 px-6 py-2 rounded min-w-44`
          }
          onClick={() => setSelectedTab(true)}
        >
          ATMs
        </button>
        <button
          className={
            !selectedTab
              ? `text-sm md:text-lg mt-6 px-6 py-2 rounded bg-green-600 text-white min-w-44`
              : `text-[#12381f] text-sm md:text-lg bg-white mt-6 px-6 py-2 rounded min-w-44`
          }
          onClick={() => setSelectedTab(false)}
        >
          Branches
        </button>
        <br />
        <br />
        {selectedTab ? <ATMs /> : <Branches />}
      </div>
    </div>
  );
};

export default FindATMBranches;
