import React from "react";

import { HiPhone } from "react-icons/hi";
import { MdEmail } from "react-icons/md";
import { MdLocationOn } from "react-icons/md";
import Heading from "../../Reuseable-Components/font/Heading";
import Paragraph from "../../Reuseable-Components/font/Paragraph";
import SubHeading from "../../Reuseable-Components/font/SubHeading";

const ContactUs = () => {
  return (
    <div className="bg-gray-100 ">
      <Heading classes={`py-10`}>Contact Us</Heading>

      <div className="flex flex-col lg:flex-row lg:justify-evenly lg:items-center px-6 ">
        <div className="lg:flex lg:flex-col lg:items-center">
          <HiPhone size={32} color={`green`} />
          <SubHeading classes={`mt-6 `}>Phone</SubHeading>
          <Paragraph classes={`mt-2`}>03592 -232008 / 231005</Paragraph>
        </div>

        <div className="my-4 lg:mt-0 lg:flex lg:flex-col lg:items-center">
          <MdEmail size={32} color={`green`} />
          <SubHeading classes={`lg:mt-6`}>Email</SubHeading>
          <Paragraph classes={`mt-2`}>headoffice@siscobank.com</Paragraph>
        </div>

        <div className="my-4 lg:mt-0 lg:flex lg:flex-col lg:items-center">
          <MdLocationOn size={32} color={`green`} />
          <SubHeading classes={`lg:mt-6`}>Address</SubHeading>
          <Paragraph classes={`mt-2`}>SISCO Bank Head Office Tadong,</Paragraph>
          <Paragraph classes={``}>Gangtok, Sikkim 737102</Paragraph>
        </div>
      </div>

      <div className="flex justify-center lg:mt-6">
        <span className="text-white text-opacity-80 text-sm 2xl:text-lg text-center rounded-t bg-[#0E381D] px-9 py-1.5 font-sans md:underline-offset-4 md:underline">
          For any ATM related queries, please contact : 03592-231005
        </span>
      </div>
    </div>
  );
};

export default ContactUs;
