const service_type = [
  { value: "Request for Cheque book", label: "Request for Cheque book" },
  { value: "Open an Account", label: "Open an Account" },
  { value: "Credit Counselling", label: "Credit Counselling" },
  { value: "Insurance Demand", label: "Insurance Demand" },
  { value: "Others", label: "Others" },
];

const branch = [
  { value: "Gangtok", label: "Gangtok" },
  { value: "Geyzing", label: "Geyzing" },
  { value: "Hee-Goan", label: "Hee-Goan" },
  { value: "Jorethang", label: "Jorethang" },
  { value: "Mangan", label: "Mangan" },
  { value: "Namchi", label: "Namchi" },
  { value: "Pakyong", label: "Pakyong" },
  { value: "Ravangla", label: "Ravangla" },
  { value: "Rongli", label: "Rongli" },
  { value: "Singtam", label: "Singtam" },
  { value: "Sombaria", label: "Sombaria" },
  { value: "Soreng", label: "Soreng" },
  { value: "Yangang", label: "Yangang" },
];

const fg_type = [
  { value: "Grievance", label: "Grievance" },
  { value: "Feedback", label: "Feedback" },
];

const fiscal_year = [
  { value: "2018-2019", label: "2018-2019" },
  { value: "2022-2023", label: "2022-2023" },
];

const circandnotiarch_year = [
  { value: "2019", label: "2019" },
  { value: "2020", label: "2020" },
];

module.exports = {
  service_type,
  branch,
  fg_type,
  fiscal_year,
  circandnotiarch_year,
};
