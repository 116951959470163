/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-distracting-elements */
/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/PublicNavbar/Navbar";
import NavbarHeader from "./Components/PublicNavbar/NavbarHeader";
import NoticePopupModal from "../src/Notice_PopUp/NoticePopupModal";

import { MdCancel } from "react-icons/md";

import creditBonanzaVideo from "./Assets/Credit_Bonaza.mp4";
import creditBonanzaVideo2 from "./Assets/Credit_Bonaza - Cropped.mp4";
import creditBonanzaVideo3 from "./Assets/Credit_Bonaza - Cropped_lg.mp4";
import GenericModal from "./Reuseable-Components/modals/GenericModal";

import agmnotice from "../src/Assets/agm_notice_15_06_23.jpg";
import NoticePopupModal_v2 from "./Notice_PopUp/NoticePopupModal_v2";

const PublicLayout = ({
  showHomeCarousel,
  setShowHomeCarousel,
  videoModalIsOpen,
  setVideoModalIsOpen,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(true);

  return (
    <>
      {/* <GenericModal
        open={modalIsOpen}
        setOpen={setModalIsOpen}
        title={" "}
        isLarge={true}
        isAdd={false}
      >
        <div>
          <div
            className="bg-slate-50 text-[#16983E] border rounded-full absolute right-10 px-2 hover:bg-slate-200 cursor-pointer hover:text-red-600 "
            onClick={() => setModalIsOpen(false)}
          >
            x
          </div>
          <div className="bg-slate-50 text-[#16983E] text-xs md:text-base border rounded-md absolute left-5 md:left-16 px-2 hover:bg-slate-200 cursor-pointer hover:text-red-600 ">
            <a
              className="outline-none"
              href="https://files.siscobank.com/AGM%20NOTICE-24th%20-29.06.2023.pdf"
              target="_blank"
              download={true}
            >
              Download Notice
            </a>
          </div>
          <img src={agmnotice} alt="notice for sisco agm" />
        </div>
      </GenericModal> */}

      <div className="">
        <GenericModal
          open={videoModalIsOpen}
          setOpen={setVideoModalIsOpen}
          title={" "}
          isLarge={true}
          isAdd={false}
        >
          <div className="flex justify-center">
            <div className="mx-0 md:mx-10 lg:mx-0 flex justify-center  relative bg-white">
              <video playsInline autoPlay loop muted className="scale-110">
                <source src={creditBonanzaVideo3} type="video/mp4" />
              </video>

              <div
                className=" text-[#16983E] absolute top-1 md:-top-6 lg:top-0 right-1 md:right-0 cursor-pointer text-xl lg:text-3xl "
                onClick={() => {
                  setVideoModalIsOpen(false);
                  setShowHomeCarousel(true);
                }}
              >
                <MdCancel color="red" />
              </div>
            </div>
          </div>
        </GenericModal>
      </div>

      {/* <NoticePopupModal /> */}
      <NoticePopupModal_v2 />
      <NavbarHeader />
      <Navbar />

      {/* <section className=" font-sans bg-gradient-to-r from-yellow-200 to-red-200 text-slate-900 font-semibold underline ">
        <a
          href="https://files.siscobank.com/sisco_post-intern.pdf"
          target="_blank"
          className="p-0"
        >
          <marquee behavior="smooth" direction="left" scrollAmount="10">
            <div className="flex items-center gap-2">
              <img src={newgif} className="h-8 pt-1" />
              <p className="pt-2">Post of Cooperative Interns</p>
            </div>
          </marquee>
        </a>
      </section> */}

      {/* <section className=" font-sans bg-gradient-to-r from-red-200 to-yellow-200 text-slate-900 font-semibold underline ">
        <a
          href="https://files.sikkimsabcco.com/corringendum%20bid%20extension%2019-05-2023.pdf"
          target="_blank"
          className="p-0"
        >
          <marquee behavior="smooth" direction="left" scrollAmount="4">
            <div className="flex items-center">
              <img src={newgif} className="h-8" />
              <p className="pt-2 px-4">
                CORRIGENDUM-II dated:19-05-2023 TO RFP NOTIFIED VIDE GEM BID NO.
                GEM/2023/B/3395791 dtd. 28.04.2023 FOR SUPPLY OF HARDWARE TO
                PACS WITHIN THE STATE OF SIKKIM (OPEN FOR GeM REGISTRERED
                BIDDERS ONLY)
              </p>
              <img src={newgif} className="h-8" />
            </div>
          </marquee>
        </a>
      </section>  */}
      <Outlet />
      <Footer />
    </>
  );
};

export default PublicLayout;
