import React from "react";

export default function SubHeading({ classes, ...rest }) {
  return (
    <div>
      <h1
        className={`text-[#0E381D] font-semibold  2xl:text-3xl lg:text-xl text-lg font-sans  ${classes}`}
      >
        {rest.children}
      </h1>
    </div>
  );
}
