import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Mobile_Banner from "../../Assets/banner_mobile_logo.webp";
import logo from "../../Assets/logo.png";
import azadiLogo from "../../Assets/azadiLogo.webp";
import tablet_Banner from "../../Assets/banner_tablet_logo.webp";
import "./Navbar.css";

export default function NavbarHeader() {
  return (
    <div className="font-body ">
      {/* <div className=" bg-[#f5f8fb]  p-4 flex justify-between items-center">
        <p className="text-xs md:text-sm">
          Call Us: 03592-231005,232008,231006(Fax)
        </p>
        <div className="">
          <button
            className=" border-solid border-r border-gray-400 px-2 text-xs md:text-sm"
            onClick={() => hanleSkipToMainContent()}
          >
            Skip To Main Content
          </button>
          <button className="border-solid  border-gray-400 px-2 text-xs md:text-sm cursor-not-allowed">
            Login
          </button>
        </div>
      </div> */}

      <div className=" sticky-sm-top">
        <div className="flex justify-center items-center lg:hidden  ">
          <NavLink to="/">
            <img
              src={logo}
              alt="Image Not Found"
              className="w-40 relative top-2 left-3   "
            />
          </NavLink>
          <NavLink to="https://amritmahotsav.nic.in/" target="_blank">
            <img
              src={azadiLogo}
              alt="Image Not Found"
              className=" h-9 relative top-2 left-10  md:left-[15rem]"
            />
          </NavLink>
        </div>

        <div className="hidden md:hidden lg:block ">
          <NavLink to="/">
            <img
              src={tablet_Banner}
              alt="Image Not Found "
              className="w-full "
            />
          </NavLink>

          <NavLink to="https://amritmahotsav.nic.in/" target="_blank">
            <img
              src={azadiLogo}
              alt="Image Not Found"
              className="inline-block  2xl:h-16 h-14 absolute top-4 left-[80%] "
            />
          </NavLink>

          <div className="hidden md:block">
            <a href="https://app.siscobank.com/" target="_blank">
              <div className="inline-block  2xl:h-16 h-14 absolute top-8 left-[70%] cursor-pointer">
                <span className="inline-block px-4 py-2  text-sm 2xl:text-xl bg-[#0E381D] hover:bg-[#228545] text-white  rounded-sm">
                  Login
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
