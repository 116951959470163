import React from "react";
import SubHeading from "../font/SubHeading";

export default function CarrersCard() {
  return (
    <>
      <div class="border max-w-md rounded-sm px-6 py-8  font-sans">
        <SubHeading className="font-bold ">
          ENGAGEMENT OF CHIEF INFORMATION SECURITY OFFICER (CISO) ON CONTRACTUAL
          BASIS{" "}
        </SubHeading>

        <div className="my-2 ">
          <span className=" px-2 py-1 rounded-2xl text-xs bg-slate-600 text-white">
            Information Technology
          </span>
        </div>
        <div className="border rounded-sm p-3 bg-slate-100 my-3 text-sm">
          <ul>
            <li>
              {" "}
              <label className="font-semibold">Post Name:</label>
              <p>Chief Information Security Officer (CISO) GRADE –VI </p>
            </li>

            <li>
              <p>
                <label className="font-semibold">Number of Vacancies: </label>
                1(one)
              </p>
            </li>

            <li>
              <p>
                <label className="font-semibold">Apply Mode: </label>
                1(one) Apply Mode- offline
              </p>
            </li>
          </ul>
        </div>

        <a
          href="https://files.siscobank.com/SISCO%20requirements-for-engagement-of-CISO%20new.pdf"
          target="_blank"
        >
          <div className="px-4 py-2 bg-green-700 rounded-sm text-white text-center hover:bg-green-900 text-sm mt-6">
            More Details
          </div>
        </a>
      </div>
    </>
  );
}
