import React from "react";
import homeSVG from "../../Assets/home.svg";
import rateSVG from "../../Assets/rate.svg";
import savingsSVG from "../../Assets/savings.svg";
import loanSVG from "../../Assets/loan.svg";
import Card from "../../Reuseable-Components/Card";
import Heading from "../../Reuseable-Components/font/Heading";
import { useState } from "react";

const Products = () => {
  const handleDefaultTab = () => {
    localStorage.removeItem("defaultTab");
  };
  const handleNewTab = () => {
    localStorage.setItem("defaultTab", 1);
  };

  const [showEng, setShowEng] = useState(true);

  let imageENGList = [
    `https://bytesberry-shared-sss.sgp1.cdn.digitaloceanspaces.com/SISCO_Bank%2FEnglish_Digital%20Fin%20Services.jpg`,
    `https://bytesberry-shared-sss.sgp1.cdn.digitaloceanspaces.com/SISCO_Bank%2FEnglish_Savings%20_%20Budget.jpg`,
    `https://bytesberry-shared-sss.sgp1.cdn.digitaloceanspaces.com/SISCO_Bank%2FEnglish_Caution%20-%20Digital%20Services.jpg`,
  ];
  let imageHINDIList = [
    `https://bytesberry-shared-sss.sgp1.cdn.digitaloceanspaces.com/SISCO_Bank%2FHindi_Digital%20Fin%20Services.jpg`,
    `https://bytesberry-shared-sss.sgp1.cdn.digitaloceanspaces.com/SISCO_Bank%2FHindi_Savings%20_%20Budget.jpg`,
    `https://bytesberry-shared-sss.sgp1.cdn.digitaloceanspaces.com/SISCO_Bank%2FHindi_Caution%20-%20Digital%20Services.jpg`,
  ];
  return (
    <>
      <section>
        <a
          href="https://www.g20.org/"
          className="mx-auto w-full bg-white cursor-pointer"
          target={"_blank"}
        >
          <img
            className="mx-auto w-fit"
            src="https://bytesberry-shared-sss.sgp1.cdn.digitaloceanspaces.com/SISCO_Bank%2FG20_logo_theme.jpg"
          ></img>
        </a>

        <div className="py-6 px-3 border-y">
          <div className="flex justify-center mx-auto w-full mb-3">
            <div
              className={`cursor-pointer border rounded-full mx-1 px-3 py-1 text-white text-sm ${
                showEng ? `bg-[#0E381D]` : `bg-slate-400`
              }`}
              onClick={() => setShowEng(true)}
            >
              English
            </div>
            <div
              className={`cursor-pointer border rounded-full mx-1 px-3 py-1 text-white text-sm ${
                showEng ? `bg-slate-400` : `bg-[#0E381D]`
              }`}
              onClick={() => setShowEng(false)}
            >
              हिंदी
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 container mx-auto">
            {showEng
              ? imageENGList?.map((img) => (
                  <>
                    <img className="mx-auto h-fit" src={img}></img>
                  </>
                ))
              : imageHINDIList?.map((img) => (
                  <>
                    <img className="mx-auto h-fit" src={img}></img>
                  </>
                ))}
          </div>
        </div>
      </section>

      <div className="container mx-auto ">
        <Heading classes={`my-10`}>Products</Heading>
        <div className="grid  md:grid-cols-2 lg:grid-cols-4">
          <Card
            Card_Image={homeSVG}
            Card_Title="Housing Loan"
            Card_Description="Owning a house for your family is always a cherished dream."
            KnowMore_Link={"/products/loans"}
            onClick={() => handleDefaultTab()}
          />
          <Card
            Card_Image={rateSVG}
            Card_Title="Term Deposit Rates"
            Card_Description="Attractive rates on your savings."
            KnowMore_Link={"/check_rates"}
          />
          <Card
            Card_Image={savingsSVG}
            Card_Title="Savings Bank"
            Card_Description="Efficient Management of Your Money."
            KnowMore_Link={"/products/accounts"}
          />
          <Card
            Card_Image={loanSVG}
            Card_Title="Salaried Loan @ 13%"
            Card_Description="Fulfill your dreams."
            KnowMore_Link={"/products/loans"}
            onClick={() => handleNewTab()}
          />
        </div>
      </div>
    </>
  );
};

export default Products;
