import React from "react";
import Heading from "../../Reuseable-Components/font/Heading";

const PhotoViewInside = ({
  photo_title,
  selectedPhotoArray,
  setSelectedPhotoTitle,
}) => {
  // console.log({ selectedPhotoArray });
  return (
    <>
      <div className="container mx-auto ">
        {/* <h1 className="font-medium text-md lg:text-4xl text-[#12381f]">
          {photo_title}  Photos
        </h1> */}
        <Heading classes={`mt-10`}>{photo_title} Photos</Heading>
        <button
          className="text-blue-600 bg-white rounded px-6  "
          onClick={() => setSelectedPhotoTitle("")}
        >
          &lt; Back to Albums
        </button>
      

    
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 px-6 py-6  ">
        {selectedPhotoArray?.map((item) => (
          <div className="flex flex-col ">
            <div className="flex justify-center md:flex-row md:max-w-xl bg-white">
              <img
                className="h-70 w-full object-cover rounded-md"
                src={
                  
                  item?.attributes?.url
                }
                alt="Image Not Found"
              />
            </div>
          </div>
        ))}
      </div>
      </div>
    </>
  );
};

export default PhotoViewInside;
