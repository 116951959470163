import React, { useEffect, useState } from "react";
import footer_logo from "../../Assets/footer_logo.webp";
import dicgc from "../../Assets/DICGCLogo_HighRes.jpg";
import qr_code from "../../Assets/QrCode_HighResolution.png";
import { FiPhoneCall } from "react-icons/fi";
import { FiMail } from "react-icons/fi";
import { BsInstagram } from "react-icons/bs";
import moment from "moment/moment";
import axios from "axios";
import useAxios from "../../hooks/useAxios";
import { BsFacebook } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";

const GET_VISTS = "api/website-visit?populate=*";

const Footer = () => {
  const { data: totalVisitedData, loading, error } = useAxios(GET_VISTS);

  const POST_WEBSITE_VISITORS = "api/website-visit";

  useEffect(() => {
    handleVistors();
  }, [loading]);

  const handleVistors = async () => {
    try {
      let currentDate = moment().format("YYYY-MM-DD");
      let localStorageData = localStorage.getItem(currentDate);
      if (
        (!localStorageData === "visited" || localStorageData === null) &&
        totalVisitedData
      ) {
        if (totalVisitedData.attributes.current_date != currentDate) {
          localStorage.setItem(currentDate, "visited");

          let data = await {
            total_visits: +totalVisitedData?.attributes?.total_visits + 1,
            daily_visit: 1,
            current_date: new Date(currentDate),
          };
          const resonse = await axios.put(
            `${process.env.REACT_APP_ENDPOINT_URL}${POST_WEBSITE_VISITORS}`,
            { data }
          );
        } else {
          localStorage.setItem(currentDate, "visited");
          let data = await {
            total_visits: +totalVisitedData?.attributes?.total_visits + 1,
            daily_visit: +totalVisitedData?.attributes?.daily_visit + 1,
          };
          const resonse = await axios.put(
            `${process.env.REACT_APP_ENDPOINT_URL}${POST_WEBSITE_VISITORS}`,
            { data }
          );
        }
      } else {
        if (totalVisitedData.attributes.current_date !== currentDate) {
          let data = await {
            total_visits: +totalVisitedData?.attributes?.total_visits + 1,
            daily_visit: +totalVisitedData?.attributes?.daily_visit + 1,
          };
          const resonse = await axios.put(
            `${process.env.REACT_APP_ENDPOINT_URL}${POST_WEBSITE_VISITORS}`,
            { data }
          );
        }
      }
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <div className=" bg-[#0e381d] font-sans ">
      {/* */}
      <div className=" container mx-auto py-9 ">
        <div className="grid grid-cols-1 md:grid-cols-4  justify-center md:gap-x-9 lg:ml-9 ">
          <div className="flex flex-col items-center px-3 col-span-1 mb-9">
            <img
              src={footer_logo}
              alt="SISCO BANK"
              className="  md:h-auto h-12"
            />
            <div className="flex gap-x-6 mt-3 ">
              <div className="text-white hover:text-yellow-500 cursor-pointer">
                <a href="https://www.facebook.com/siscobankltd" target="_blank">
                  <FaFacebookF size={20} />
                </a>
              </div>
              <span className="text-white hover:text-yellow-500 cursor-pointer">
                <a
                  href="https://www.instagram.com/p/CTlY0DiBhnC/?utm_medium=share_sheet"
                  target="_blank"
                >
                  <BsInstagram size={20} />
                </a>
              </span>
            </div>

            <div className="mt-10 flex flex-col justify-center items-center">
              <img src={dicgc} alt="SISCO BANK" className="  w-24 h-24" />
              <p className="text-white text-sm py-4 cursor-pointer hover:underline">
                <a href="https://www.dicgc.org.in/" target="_blank">
                  SISCO is registered with DICGC
                </a>
              </p>

              <img
                src={qr_code}
                alt="SISCO BANK"
                className=" w-24 h-24 md:mt-0 mt-4 "
              />
              <p className=" text-white text-sm py-4 cursor-pointer hover:underline">
                <a href="https://www.dicgc.org.in/" target="_blank">
                  (DICGC Website)
                </a>
              </p>
            </div>
          </div>

          <div className="col-span-3 grid  grid-cols-1  md:grid-cols-3 gap-y-9 ml-9">
            <div className="text-white  text-sm 2xl:text-lg   ">
              <h1 className="text-[#ea9e16] text-xl 2xl:text-2xl  pb-2 ">
                Products
              </h1>
              <NavLink to="/products/accounts">
                <p className="hover:underline ">Accounts</p>
              </NavLink>
              <NavLink to="/products/deposits">
                <p className="hover:underline ">Deposits</p>
              </NavLink>
              <NavLink to="/products/loans">
                <p className="hover:underline ">Loans</p>
              </NavLink>
              <NavLink to="/insurance/pradhan_mantri_yojana">
                <p className="hover:underline ">Insurance</p>
              </NavLink>
            </div>

            <div className="text-white  text-sm 2xl:text-lg">
              <h1 className="text-[#ea9e16] text-xl 2xl:text-2xl pb-2">
                About us
              </h1>
              <NavLink to="/about_us/history">
                <p className="hover:underline ">History</p>
              </NavLink>

              <NavLink to="/about_us/awards_and_recognition">
                <p className="hover:underline ">Awards and Recognitions</p>
              </NavLink>
              <NavLink to="/about_us/keymilestone">
                <p className="hover:underline ">Key Milestones </p>
              </NavLink>
              <NavLink to="/media/photo_gallery">
                <p className="hover:underline ">Photo Gallery </p>
              </NavLink>
              {/* <NavLink
                to="https://files.siscobank.com/deaf_Account.pdf"
                target="_blank"
              >
                <p className="hover:underline pt-0.5 ">Deaf Accounts </p>
              </NavLink> */}
              <NavLink to="/deaf-accounts">
                <p className="hover:underline pt-0.5 ">Deaf Accounts </p>
              </NavLink>
            </div>

            <div className="text-white  text-sm 2xl:text-lg">
              <h1 className="text-[#ea9e16] text-xl  2xl:text-2xl pb-2">
                Get in Touch
              </h1>
              <NavLink to="/get_in_touch/find_atms_or_branches">
                <p className="hover:underline">Find ATMs/Branches</p>
              </NavLink>
              <NavLink to="/get_in_touch/feedback_or_grievance_redressal">
                <p className="hover:underline">Feedback/Grievance Redressal</p>
              </NavLink>
              <NavLink to="get_in_touch/request_for_service">
                <p className="hover:underline">Request for Service</p>
              </NavLink>
              <NavLink to="/get_in_touch/unclaimed_deposit">
                <p className="hover:underline">Unclaimed Deposit </p>
              </NavLink>
            </div>

            <div className="text-white  text-sm 2xl:text-lg">
              <h1 className="text-[#ea9e16]  text-xl 2xl:text-2xl pb-2">
                Helpful Links
              </h1>
              <NavLink to="http://rbi.org.in/" target="_blank">
                <p className="hover:underline">Reserve Bank of India</p>
              </NavLink>
              <NavLink to="http://www.nabard.org/" target="_blank">
                <p className="hover:underline">NABARD</p>
              </NavLink>
              <NavLink to="http://nafscob.org/" target="_blank">
                <p className="hover:underline">NAFSCOB</p>
              </NavLink>
              <NavLink
                to="https://sikkimpower.co.in/Qbilpay.php"
                target="_blank"
              >
                <p className="hover:underline">
                  Electricity Bill Payment (Other Zones){" "}
                </p>
              </NavLink>
              <NavLink
                to="http://power.sikkim.gov.in/electricity-bill-payment/quick-pay-online/"
                target="_blank"
              >
                <p className="hover:underline">
                  Electricity Bill Payment (Gangtok Zone){" "}
                </p>
              </NavLink>
            </div>

            <div className="text-white space-y-2 2xl:text-lg ">
              <h1 className="text-[#ea9e16]  text-xl 2xl:text-2xl pb-2 ">
                Contact us
              </h1>
              <div className="flex items-center gap-4">
                <FiPhoneCall size={16} />
                <p>03592 -232008 / 231005</p>
              </div>
              <div className="flex items-center gap-4">
                <FiMail size={16} />
                <p>headoffice@siscobank.com</p>
              </div>
            </div>

            <div className="text-white space-y-2 2xl:text-lg ">
              <h1 className="text-[#ea9e16]  text-xl 2xl:text-2xl pb-2 ">
                Privacy Policies
              </h1>
              <div className="flex items-center gap-4">
                <NavLink to="/privacy_policy">
                  <p className="hover:underline">Privacy Policy</p>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center pb-1">
        <span className="text-gray-200 text-center  text-xs 2xl:text-lg px-4 py-1 ">
          Copyright © SISCO Bank
        </span>
      </div>

      <div className="flex justify-center py-2 text-white gap-2 text-xs 2xl:text-base bg-green-900">
        <span className=" text-center  pr-2 border-r border-gray-400 border-opacity-30">
          <span className="text-gray-300 mr-1">Total Visits:</span>
          {+totalVisitedData?.attributes?.total_visits}
        </span>
        <span className="text-center">
          <span className="text-gray-300 mr-1"> Visits Today:</span>
          {+totalVisitedData?.attributes?.daily_visit}
        </span>
      </div>
    </div>
  );
};

export default Footer;
