import React, { useEffect } from "react";
import "./KeyMilestone.css";
import useAxios from "../../hooks/useAxios";
import Heading from "../../Reuseable-Components/font/Heading";
const GET_KEYS_MILESTONE = "api/keys-and-milestones?populate=*";

const KeyMilestone = () => {
  const { data, loading, error } = useAxios(GET_KEYS_MILESTONE);
  // console.log("Keys&MileStoneData", data);

  var items = document.querySelectorAll(".timeline li");

  function isElementInViewport(el) {
    var rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  function callbackFunc() {
    for (var i = 0; i < items.length; i++) {
      if (isElementInViewport(items[i])) {
        if (!items[i].classList.contains("in-view")) {
          items[i].classList.add("in-view");
        }
      } else if (items[i].classList.contains("in-view")) {
        items[i].classList.remove("in-view");
      }
    }
  }

  window.addEventListener("load", callbackFunc);
  window.addEventListener("scroll", callbackFunc);
  window.addEventListener("resize", callbackFunc);

  let newArray = data?.sort((a, b) => {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  });

  return (
    <div className="container mx-auto font-sans pt-10">
      {/* <h1 className="text-center my-20 font-medium text-2xl lg:text-5xl text-[#12381f]">
        Key &amp; Milestones
      </h1> */}
      <Heading>Key &amp; Milestones</Heading>

      <div className="my-6">
        <section class="timeline">
          <ul>
            {newArray?.map((keyMilestoneData) => (
              <li>
                <div>
                  <time className="text-white text-2xl font-sans  " >
                    {keyMilestoneData?.attributes?.milestone_date}
                  </time>
                  <div className="discovery">
                    <img
                      src={
                        keyMilestoneData?.attributes?.milestone_icons?.data[0]?.attributes?.url
                      }
                      alt="Image Not Found"
                    />
                    <h1 className="text-lg text-center font-sans  font-semibold text-green-800 ">
                      {keyMilestoneData?.attributes?.milestone_description}
                    </h1>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </section>
      </div>
    </div>
  );
};

export default KeyMilestone;
