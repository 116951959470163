import React from "react";
import Heading from "../../Reuseable-Components/font/Heading";
import Paragraph from "../../Reuseable-Components/font/Paragraph";
import SubHeading from "../../Reuseable-Components/font/SubHeading";

export default function RoleOfSiscoBank() {
  return (
    <div className="container md:mx-auto   py-12">
      <div className="px-6">
      
      <SubHeading>Role of SISCO Bank in Financial Inclusion</SubHeading>
      <Paragraph classes={`pt-2`}>
        The Sikkim State Co-operative Bank Ltd. is working on various schemes
        and plans in order to attain the aspiration of financial inclusion in
        the State of Sikkim effectively. Here are some of the schemes introduced
        by the Bank in order to achieve its goals:
      </Paragraph>

      <Paragraph classes={` pl-4 list-disc pt-3`}>
        <li>
          These are no-frill accounts where account holders do not have to
          maintain any minimum balance or minimum deposit. These account holders
          can withdraw cash at any ATM or at the bank branch. They should also
          be given the opportunity to make use of electronic payment channels
          for receiving and transferring money to others.
        </li>
        <li>
          The banks have simple Know Your Client (KYC) regulations for the less
          fortunate people of the society. There are many people in rural areas
          that are unable to open bank accounts due to strict KYC norms. Hence,
          the RBI wants banks to have simplified KYC requirements particularly
          if a low-income individual is interested in opening a bank account
          with an amount not above Rs.50, 000. It also wants minimal KYC norms
          if the overall credit in the accounts does not go above Rs.1 lakh for
          1 year. banks have also stating to accept Aadhaar Card as identity
          proof as well as address proof since most people belonging to
          low-income groups have made Aadhaar card in their names.
        </li>
        <li>
          Bank is deliberately working towards rural population as most of its
          branches are located in rural areas; they have been created with a
          view of serving primarily the rural areas with basic banking and
          financial services.
        </li>
        <li>
          Kissan Credit Cards (KCC): Bank has been provide Kissan Credit Cards
          exclusively to small farmers who earn very low incomes and have very
          limited funds due to which they cannot invest in proper farming tools,
          fertilizers, pesticides, crop seeds, etc. These Kissan Credit Cards
          are intended to help farmers make instant purchases whenever required.
          Many a time, farmers give up on purchasing things required for their
          occupation due to lack of funds. Sikkim State Co-operative Bank Ltd.
          has been among the highest number of KCC issuing Bank in the State for
          the successive years. Further bank has issued total of Rs: 2.57 crore
          KCC in the financial year 2019-20 to 564 beneficiaries.
        </li>
        <li>
          ATMs: In order to increase the availability of physical cash for the
          people, bank has also setup in-house ATMs in all its branches
          throughout the state.
        </li>
        <li>
          Financial literacy: Financial Literacy Camp plays a very essential
          role in the course of financial inclusion, need of financial literacy
          is even greater considering the low levels of literacy and the large
          section of the population, which still remains out of the formal
          financial set-up especially in the rural areas. Sikkim State
          Co-operative Bank Ltd. in collaboration with NABARD (National Bank for
          Agriculture and Rural Development) has set up two (2) Financial
          Literacy and Credit Counseling (FLCC) centers which perform the
          function of travelling to various gram panchayats, village’s, schools
          and PACS/MPCS to arrange the Financial and credit counseling camps ,
          to be precise the bank has organized 120 + such camps in the financial
          year 2019-20.
        </li>
        <li>
          Cash Kendra: Primary Agriculture Credit Society (PACS) is being set up
          as Deposit Mobilization Agency (DMA) for the bank. As we are well
          aware that PACS is the basic unit and small co-operative credit
          institution which works on the grassroots. Primary Agricultural Credit
          Societies (PACS) occupy a predominant position in the co-operative
          credit structure and form its base. A PACS is organized at the grass
          roots level of a village or a group of small villages. As such, the
          health and strength of the co-operative credit movement depends
          crucially upon the health and strength of these societies. Taking in
          view the reach of PACS to the rural masses in our state bank has come
          up with a strategy of Cash Kendra to make them strong and well
          ascertain.
        </li>
        <li>
          Mobile ATM van or Cash on wheels: Bank with the assistance from NABARD
          (National Bank for Agriculture and Rural Development) has launched two
          (2) mobile ATM vans in the year 2018 which is endlessly operating to
          fulfill the need of physical cash in the rural areas of the state,
        </li>
      </Paragraph>

      <Paragraph classes={`pt-2`}>
        Apart from helping to withdraw cash in a hassle-free manner, the mobile
        ATM van is also spreading awareness on a wide range of services being
        offered by the bank and financial literacy among rural masses.
      </Paragraph>
      <Paragraph classes={`pt-2`}>
        Further mobile ATM service is becoming very useful means for fulfilling
        the necessity of physical cash to the far-flung location at the time of
        COVID-19 pandemic.
      </Paragraph>
      
</div>
    </div>
  );
}
