import React from "react";
import { useState } from "react";

import useAxios from "../../hooks/useAxios";
import Heading from "../../Reuseable-Components/font/Heading";
import SearchBox from "../../Reuseable-Components/SearchBox";

const GET_BRANCH_LOCATIONS = "api/branch-location-details";

const Branches = () => {
  const [isMapLoading, setIsMapLoading] = useState(true);

  const onMyFrameLoadHandler = async (e) => {
    let loadingTime = await e.timeStamp;

    if (loadingTime) {
      setInterval(() => {
        setIsMapLoading(false);
      }, 1000);
    } else {
      setIsMapLoading(true);
    }
  };

  const defaultSrc =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d905172.9088023758!2d88.46718125!3d27.598747749999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e6a56a5805eafb%3A0xa4c4b857c39b5a04!2sSikkim!5e0!3m2!1sen!2sin!4v1664521213954!5m2!1sen!2sin";
  const [stringSrc, setStringSrc] = useState(defaultSrc);
  const [searchText, setSearchText] = useState("");

  const { data, error, loading } = useAxios(GET_BRANCH_LOCATIONS);
  if (loading)
    return (
      <div className="flex items-center justify-center text-green-500 my-10">
        <div
          className="spinner-border animate-spin inline-block w-8 h-8 md:w-14 md:h-14 border-4 rounded-full"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  if (error) return <p>Error :(</p>;
  console.log("Branch Locations Table data- ", data);

  let newArray = data.sort((a, b) => {
    if (a.attributes.branch_name < b.attributes.branch_name) {
      return -1;
    }
    if (a.attributes.branch_name > b.attributes.branch_name) {
      return 1;
    }
    return 0;
  });

  console.log("Sorted array: ", newArray);

  return (
    <div className="mx-auto">
      {isMapLoading && (
        <div className="flex justify-center h-[500px] w-full absolute z-50">
          <div
            className=" m-auto spinner-border animate-spin inline-block w-8 h-8 md:w-14 md:h-14 border-4 rounded-full text-green-500"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      <div className="google-map-code py-4 relative">
        <iframe
          src={stringSrc}
          width="100%"
          height="500"
          frameborder="0"
          style={{ border: 0 }}
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
          onLoad={(e) => onMyFrameLoadHandler(e)}
        ></iframe>
      </div>
      <div className="flex justify-center pb-4 font-body">
        {/* <span className="font-bold text-md md:text-4xl text-blue-700">
          IMPORTANT CONTACT DETAILS
        </span> */}
        <Heading>IMPORTANT CONTACT DETAILS</Heading>
      </div>
      <br />

      <div class=" mx-1 md:mx-20 pb-4 flex flex-col">
        <SearchBox searchText={searchText} setSearchText={setSearchText} />
        <div class="sm:overflow-scroll overflow-y-hidden overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden">
              <table className="min-w-full bg-green-50">
                <thead className=" text-center">
                  <tr>
                    <th
                      scope="col"
                      class="text-sm md:text-lg font-sans  text-gray-900 px-6 py-4 border-r border-l border-t border-black"
                    >
                      Branch
                    </th>
                    <th
                      scope="col"
                      class="text-sm md:text-lg font-sans  text-gray-900 px-6 py-4 border-r border-t border-black"
                    >
                      Branch Address
                    </th>
                    <th
                      scope="col"
                      class="text-sm md:text-lg font-sans  text-gray-900 px-6 py-4 border-r border-t border-black"
                    >
                      IFSC Code
                    </th>
                    <th
                      scope="col"
                      class="text-sm md:text-lg font-sans  text-gray-900 px-6 py-4 border-r border-t border-black"
                    >
                      Email ID
                    </th>
                    <th
                      scope="col"
                      class="text-sm md:text-lg font-sans text-gray-900 px-6 py-4 border-r border-t border-black"
                    >
                      Contact Number
                    </th>
                    <th
                      scope="col"
                      class="text-sm md:text-lg font-sans text-gray-900 px-6 py-4 border-r border-t border-black"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {searchText === "" ? (
                    <>
                      {newArray.map((branch) => (
                        <tr>
                          <td class="text-sm md:text-lg text-gray-900 font-sans px-6 py-4 whitespace-nowrap border-x border-y border-black">
                            {branch?.attributes?.branch_name}
                          </td>
                          <td class="text-sm md:text-lg text-gray-900 font-sans px-6 py-4 whitespace-nowrap border-x border-y border-black">
                            {branch?.attributes?.branch_address}
                          </td>
                          <td class="text-sm md:text-lg text-gray-900 font-sans px-6 py-4 whitespace-nowrap border-x border-y border-black">
                            {branch?.attributes?.ifsc_code}
                          </td>
                          <td class="text-sm md:text-lg text-gray-900 font-sans px-6 py-4 whitespace-nowrap border-x border-y border-black">
                            {branch?.attributes?.email_id}
                          </td>
                          <td class="text-sm md:text-lg text-gray-900 font-sans px-6 py-4 whitespace-nowrap border-x border-y border-black">
                            {branch?.attributes?.contact_no}
                          </td>
                          <td class="text-sm md:text-lg font-sans text-gray-900  px-6 py-4 whitespace-nowrap border-x border-y border-black">
                            <a
                              className="hover:bg-gray-300 hover:underline text-blue-600"
                              href="#"
                              onClick={() =>
                                setStringSrc(branch?.attributes?.location_src)
                              }
                            >
                              View on Map
                            </a>
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <>
                      {newArray
                        .filter((location) =>
                          location.attributes?.branch_address
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                        )
                        .map((branch) => (
                          <tr className="">
                            <td class="text-lg text-gray-900 font-sans  px-6 py-4 whitespace-nowrap border-x border-y border-black">
                              {branch?.attributes?.branch_name}
                            </td>
                            <td class="text-lg text-gray-900 font-sans px-6 py-4 whitespace-nowrap border-x border-y border-black">
                              {branch?.attributes?.branch_address}
                            </td>
                            <td class="text-lg text-gray-900 font-sans px-6 py-4 whitespace-nowrap border-x border-y border-black">
                              {branch?.attributes?.ifsc_code}
                            </td>
                            <td class="text-lg text-gray-900 font-sans px-6 py-4 whitespace-nowrap border-x border-y border-black">
                              {branch?.attributes?.email_id}
                            </td>
                            <td class="text-lg text-gray-900 font-sans px-6 py-4 whitespace-nowrap border-x border-y border-black">
                              {branch?.attributes?.contact_no}
                            </td>
                            <td class="text-lg font-sans  text-gray-900  px-6 py-4 whitespace-nowrap border-x border-y border-black">
                              <a
                                className="hover:bg-gray-300 hover:underline font-sans"
                                href="#"
                                onClick={() =>
                                  setStringSrc(branch?.attributes?.location_src)
                                }
                              >
                                View on Map
                              </a>
                            </td>
                          </tr>
                        ))}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* -----------------------------------------------------------TABLE END------------------------------------------------------------------------------- */}
    </div>
  );
};

export default Branches;
