import React, { useState } from "react";

import { BsFacebook } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";

const BlogPageCard = ({
  blog_image,
  blog_title,
  blog_caption,
  setActiveBlogDetails,
  setIsDetailsActive,
  blog,
}) => {
  console.log({ blog });

  // const [selectedBlogId, setSelectedBlogId] = useState(" ");
  const onClickReadMore = () => {
    setIsDetailsActive(true);
    setActiveBlogDetails(blog);
  };
  return (
    <>
      {/* {selectedBlogId === " " ? ( */}
      <section>
        <div class="overflow-hidden shadow-lg rounded-lg  w-80 md:w-80 cursor-pointer m-auto bg-green-50 transition duration-500 ease-in-out transform hover:translate-y-2 hover:shadow-2xl">
          <div class="w-full block h-full">
            <img
              alt="blog photo"
              src={blog_image}
              className="w-full object-cover"
            />
            <div class="w-full p-4">
              <span class="mr-2 py-1 px-4 text-xs font-medium bg-gray-300 rounded">
                Blog
              </span>
              <span class="mr-2 py-1 px-2 text-gray-600  text-sm font-medium">
                2mins read
              </span>
              <p class="text-gray-800 dark:text-white text-xl font-semibold mb-2 pt-3">
                {blog_title}...
              </p>
              <p class="text-gray-600 dark:text-gray-600 font-medium text-sm">
                {blog_caption}!
              </p>

              {/* <Link to={{pathname : "/blogCard",blogProps:{blogid: blog_id}}} > */}
              <div className="py-4">
                <button
                  className="text-white   bg-[#22C55E]  rounded-lg px-4 py-1 text-sm"
                  onClick={onClickReadMore}
                >
                  Read more
                </button>
              </div>
              {/* </Link> */}

              <div className="flex justify-center gap-x-4 mt-3 ">
                <div className="text-black hover:text-green-500 cursor-pointer">
                  <a
                    href="https://www.facebook.com/siscobankltd"
                    target="_blank"
                  >
                    <BsFacebook size={20} />
                  </a>
                </div>
                <span className="text-black hover:text-green-500 cursor-pointer">
                  <a
                    href="https://www.instagram.com/p/CTlY0DiBhnC/?utm_medium=share_sheet"
                    target="_blank"
                  >
                    <BsInstagram size={20} />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ) : ( */}
      {/* <div className="">
         
           <BlogCard
            blog_id={blog_id}
            blog_caption={blog_caption}
            blog_title={blog_title}
            blog_description={blog_description}
            blog_image={blog_image}
            setSelectedBlogId={setSelectedBlogId}
            />
          </div> */}
      {/* )} */}
    </>
  );
};

export default BlogPageCard;
