import React, { useState } from "react";
import { useEffect } from "react";
import useAxios from "../../hooks/useAxios";
import Heading from "../../Reuseable-Components/font/Heading";
import FinancialInclusions_Incorporated from "./FinancialInclusions_Incorporated";
import RoleOfSiscoBank from "./RoleOfSiscoBank";
import WhatIsFinancialInclusion from "./WhatIsFinancialInclusion";

const GET_FINANCIAL_INCLUSION = "api/financial-inclusions?populate=*";

const FinancialInclusion = () => {
  const [activeTab, setActiveTab] = useState(3);
  const { data, error, loading } = useAxios(GET_FINANCIAL_INCLUSION);

  const activetab = "bg-[#0E381D] text-white font-medium";
  const nonactivetab = "text-gray-600 font-semibold";

  const changeTab = (id) => {
    setActiveTab(id);
  };

  if (loading)
    return (
      <div className="flex items-center justify-center text-green-500 my-10">
        <div
          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  if (error) return <p>Error :(</p>;

  // console.log("Financial_Inclusion_Data + showiD", data);

  // console.log("activeTab", activeTab);

  return (
    <div className="container mx-auto my-10 ">
      <div className=" ">
      <Heading>Financial Inclusion</Heading>

      <div className="flex flex-col lg:flex-row lg:gap-3 lg:my-8 gap-4 px-6 pt-4">
        {data.map((financial_inclusion) => (
          <div
            className={` font-sans   px-6 py-2  rounded-3xl cursor-pointer shadow text-sm  2xl:text-lg ${
              financial_inclusion.id === activeTab ? activetab : nonactivetab
            }`}
            onClick={() => changeTab(financial_inclusion.id)}
          >
            {financial_inclusion.attributes.financial_inclusion_details}
          </div>
        ))}
      </div>
      {activeTab === 1 && <WhatIsFinancialInclusion />}
      {activeTab === 2 && <RoleOfSiscoBank />}
      {activeTab === 3 && <FinancialInclusions_Incorporated />}
    </div>
    </div>
  );
};

export default FinancialInclusion;
