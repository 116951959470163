import React from "react";
import Error from "./Error";

const InputTextArea = ({
  register,
  value,
  placeholder,
  label,
  name,
  onChange,
  errors,
  clearErrors,
  ...rest
}) => {
  const setValueHandler = (value) => {
    rest.setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  // console.log("Error", errors);
  return (
    <div>
      <label
        className="block uppercase tracking-wide text-gray-700 text-base font-bold mb-2"
        for={label}
      >
        {label} {<span className="text-red-600">*</span>}
        {/* required && */}
      </label>
      <textarea
        {...register(name, { required: rest.required, maxLength: 250 })} //required has been passed under '...rest'
        className={`block w-full bg-gray-50 text-gray-700 
    rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white
    ${
      errors[name]
        ? `border-2  border-red-500`
        : `focus:border-2  focus:border-green-500`
    }
    `}
        id={label}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          setValueHandler(e.target.value);
          // setValue([name], e.target.value, { shouldValidate: true });
          console.log(e.target.value);
          clearErrors(name);
        }}
      />
      {errors[name] && errors[name].type === "required" && (
        <Error message={`${label} field is required!`} />
      )}
      {errors[name] && errors[name].type === "maxLength" && (
        <Error message="Maximum length for description exceeded." />
      )}
    </div>
  );
};
export default InputTextArea;
