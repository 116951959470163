import React from "react";

import { useState } from "react";
import { useForm } from "react-hook-form";
import InputDropDown from "../../Reuseable-Components/InputDropDown";
import SearchBox from "../../Reuseable-Components/SearchBox";
import useAxios from "../../hooks/useAxios";

import { circandnotiarch_year } from "../utilities/constant/inputdropdown";
import { useEffect } from "react";
import Heading from "../../Reuseable-Components/font/Heading";
import SubHeading from "../../Reuseable-Components/font/SubHeading";
import { NavLink } from "react-router-dom";

const GET_ARCHIVE_CIRCULAR_DETAILS =
  "api/archive-tab-circular-details?populate=*";

const CircularsAndNotificationsArchive = () => {
  const [selectedYear, setSelectedYear] = useState({});
  const [searchText, setSearchText] = useState("");
  const [archives, setArchives] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();

  const { data, error, loading } = useAxios(GET_ARCHIVE_CIRCULAR_DETAILS);

  useEffect(() => {
    console.log("Selected year", selectedYear.value);
    const filteredData = data?.filter(
      (archives) =>
        parseInt(archives?.attributes?.archive_year) ===
        parseInt(selectedYear.value)
    );
    //parseInt, which is used for type-casting, is used here to convert the year stored in text format in Strapi to int format.
    //parseInt can only be used in case where the string that we have stored does not contain any alphabets or special symbols.
    //We are using parseInt here before the comparison so that the two compared values are of the same format.
    setArchives(filteredData);
  }, [selectedYear]);
  // console.log("archives array",archives)

  if (loading)
    return (
      <div className="flex items-center justify-center text-green-500 my-10">
        <div
          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  if (error) return <p>Error :(</p>;
  // console.log("Archive Circular data- ", data);

  return (
    <div className="container mx-auto font-sans">
      <div className="lg:mx-0   mx-10">
        {/* <h1 className="text-center my-20 font-medium text-2xl lg:text-5xl text-[#12381f]">
          Circulars &amp; Notifications Archive
        </h1> */}
        <Heading classes={`my-10`}>
          Circulars &amp; Notifications Archive
        </Heading>
        <div className="pb-5">
          {/* <span className=" font-serif font-semibold text-gray-500 text-lg  mb-2">
            Please select a year to view the archived circulars.
          </span> */}
          <SubHeading classes={`mb-2`}>
            Please select a year to view the archived circulars.
          </SubHeading>
        </div>
        <InputDropDown
          setDropdownValue={setSelectedYear}
          register={register}
          required={false}
          label="Select Circulars and Notifications Year"
          classes={`rounded text-sm w-full`}
          onChange={null}
          data={circandnotiarch_year}
          name="circandnotiarch_year"
          errors={errors}
          clearErrors={clearErrors}
        />

        {/* 'archives' used below is an array fetched using filter(). It is an array of circular objects 
        archives.length is used to check if no year is selected. It returns the length of the filtered array 'archives'
        If no year is selected then the table should not be displayed instead an empty div is being displayed achieved using ternary operator */}

        {archives?.length > 0 ? (
          <>
            <SearchBox searchText={searchText} setSearchText={setSearchText} />
            <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                <div class="overflow-hidden sm:overflow-scroll">
                  <table className="min-w-full bg-green-50">
                    <thead className=" text-center">
                      <tr>
                        <th
                          scope="col"
                          class="text-sm lg:text-lg font-bold text-gray-900 px-3 py-2 lg:px-6 lg:py-4 border-r border-l border-t border-black"
                        >
                          Circular Title
                        </th>
                        <th
                          scope="col"
                          class="text-sm lg:text-lg font-bold text-gray-900 px-3 py-2 lg:px-6 lg:py-4 border-r border-t border-black"
                        >
                          Circular Date
                        </th>
                        <th
                          scope="col"
                          class="text-sm lg:text-lg font-bold text-gray-900 px-3 py-2 lg:px-6 lg:py-4 border-r border-t border-black"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {/* In the lines below we are using one more ternary conditional rendering, checking if the searchbox is empty or not
                      using .map  or  .filter.map  accordingly   */}
                      {searchText === "" ? (
                        <>
                          {archives.map((circular) => (
                            <tr>
                              <td class="text-sm lg:text-lg text-gray-900 font-normal px-3 py-2 lg:px-6 lg:py-4 whitespace-nowrap border-x border-y border-black">
                                {circular?.attributes?.circular_title}
                              </td>
                              <td class="text-sm lg:text-lg text-gray-900 font-normal px-3 py-2 lg:px-6 lg:py-4 whitespace-nowrap border-x border-y border-black">
                                {circular?.attributes?.circular_date}
                              </td>
                              <td class="text-sm lg:text-lg font-normal text-gray-900  px-3 py-2 lg:px-6 lg:py-4 whitespace-nowrap border-x border-y border-black">
                                <NavLink
                                  to={
                                   
                                    circular?.attributes?.view_details_url.substring(
                                      1
                                    )
                                  }
                                >
                                  <button className="text-white text-sm lg:text-lg bg-blue-600 shadow mt-1 lg:mt-4 px-2 py-1 lg:px-6 lg:py-4 rounded hover:bg-blue-800 min-w-44">
                                    View Details
                                  </button>
                                </NavLink>
                              </td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <>
                          {/* Here we have one more conditional rendering which will
                          keep in check the search term provided. If there is no
                          match, we will display 'No records found!' in the 'tr'
                          tag. */}

                          {archives?.filter((searchedcircular) =>
                            searchedcircular.attributes?.circular_title
                              .toLowerCase()
                              .includes(searchText.toLowerCase())
                          ).length > 0 ? (
                            <>
                              {archives
                                .filter((searchedcircular) =>
                                  searchedcircular.attributes?.circular_title
                                    .toLowerCase()
                                    .includes(searchText.toLowerCase())
                                )
                                .map((circular) => (
                                  <tr>
                                    <td class="text-sm lg:text-lg text-gray-900 font-normal px-3 py-2 lg:px-6 lg:py-4 whitespace-nowrap border-x border-y border-black">
                                      {circular?.attributes?.circular_title}
                                    </td>
                                    <td class="text-sm lg:text-lg text-gray-900 font-normal px-3 py-2 lg:px-6 lg:py-4 whitespace-nowrap border-x border-y border-black">
                                      02 April 2019
                                    </td>
                                    <td class="text-sm lg:text-lg font-normal text-gray-900  px-3 py-2 lg:px-6 lg:py-4 whitespace-nowrap border-x border-y border-black">
                                      <NavLink
                                        to={
                                          circular?.attributes?.view_details_url.substring(
                                            1
                                          )
                                        }
                                      >
                                        <button className="text-white text-sm lg:text-lg bg-blue-600 shadow mt-1 lg:mt-4 px-2 py-1 lg:px-6 lg:py-4 rounded hover:bg-blue-800 min-w-44">
                                          View Details
                                        </button>
                                      </NavLink>
                                    </td>
                                  </tr>
                                ))}
                            </>
                          ) : (
                            <tr
                              scope="col"
                              className="text-red-600 text-sm lg:text-lg mt-6 font-semibold"
                            >
                              No records found!
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div></div>
          </>
        )}
      </div>
    </div>
  );
};

export default CircularsAndNotificationsArchive;
