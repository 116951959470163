import Captcha from "react-alphanum-captcha";
import "./captcha.css";

const ReCaptcha = ({ setCaptchaSuccess }) => {
  return (
    <section>
      <p className="mb-2 text-yellow-700 text-sm">
        (Please type the captcha provided)
      </p>
      <Captcha onChange={(status) => setCaptchaSuccess(status)} />
    </section>
  );
};

export default ReCaptcha;
