import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import Heading from "../../Reuseable-Components/font/Heading";
import SubHeading from "../../Reuseable-Components/font/SubHeading";
import InputDropDown from "../../Reuseable-Components/InputDropDown";

//import { newsandevents_archive_year } from "../utilities/constant/inputdropdown";

//import newsandeventsarchivepdf from "../../Assets/Annual_Report_Archives_2018_2019.pdf";

const NewsandEventsArchive = () => {
  //const [selectedNewsAndEventsArchiveYear, setSelectedNewsAndEventsArchiveYear] = useState({});

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();

  return (
    <div className="container mx-auto font-sans">
      <div className="lg:mx-0  mx-10">
        {/* <h1 className="text-center my-20 font-medium text-2xl lg:text-5xl text-[#12381f]">
          News &amp; Events Archive
        </h1> */}
        <Heading classes={`my-10`}>News &amp; Events Archive</Heading>
        <div className="pb-4">
          {/* <span className=" font-serif font-semibold text-gray-500 text-lg  mb-2">
            Please select a year to view archived news
          </span> */}
          <SubHeading classes={`mb-2`}>
            Please select a year to view archived news
          </SubHeading>
        </div>

        <InputDropDown
          //setDropdownValue={setSelectedNewsAndEventsArchiveYear}
          register={register}
          required={false}
          label="Select News and Events Year"
          classes={`rounded text-sm w-full`}
          onChange={null}
          //data={newsandevents_archive_year}
          name="newsandevents_archive_year"
          errors={errors}
          clearErrors={clearErrors}
        />
        {/* 
        {setSelectedNewsAndEventsArchiveYear.value === "#" && (
          <div className="h-screen">
            <object
              className=""
              height="100%"
              width="100%"
              type="application/pdf"
              data={newsandeventsarchivepdf}
            ></object>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default NewsandEventsArchive;
