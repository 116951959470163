import React from "react";
import Heading from "../../Reuseable-Components/font/Heading";
import Paragraph from "../../Reuseable-Components/font/Paragraph";
import SubHeading from "../../Reuseable-Components/font/SubHeading";

const CircularsandNotificationMedia = () => {
  return (
    <div className="container mx-auto pb-20 px-4">
      {/* <h1 className="text-center my-20 font-medium text-2xl lg:text-5xl text-[#12381f]">
        Circulars and Notification
      </h1> */}
      <Heading classes={`my-10`}> Circulars and Notification</Heading>
      <div className="flex justify-start shadow-lg  border-2">
        <SubHeading classes={`p-2`}>
          Stay tuned for latest circulars...
        </SubHeading>
      </div>
    </div>
  );
};

export default CircularsandNotificationMedia;
