import React, { Fragment } from "react";

export const InputFieldCalc = ({ label, onChange, value, error }) => {
  return (
    <Fragment>
      <div className="flex justify-start 2xl:text-xl font-sans font-semibold">
        <div className="mb-3 xl:w-96">
          <label>{label}</label>
          <input
            value={value}
            onChange={onChange}
            type="number"
            className="block  px-3 py-1.5  focus:text-gray-700 focus:bg-white
             focus:border-green-600 focus:outline-none border border-solid border-gray-300
             rounded transition ease-in-out"
          />
          {value === "" || value === null || value === undefined ? (
            <div className="text-red-600 text-sm italic">{error}</div>
          ) : (
            ""
          )}
        </div>
      </div>
    </Fragment>
  );
};
