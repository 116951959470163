import React from "react";
import Heading from "../../Reuseable-Components/font/Heading";
import Paragraph from "../../Reuseable-Components/font/Paragraph";

const History = () => {
  return (
    <div className="container mx-auto my-12">
      <div>
        <Heading>History</Heading>

        <div className="lg:px-0 px-4 pt-5">
          <Paragraph classes={`pt-3`}>
            Sikkim State Co-operative Bank Limited also known as SISCO Bank was
            granted license by the Reserve Bank of India (Lic.No. RPCD.SIK 55-S)
            for opening the first and the only Co-operative Bank in the State of
            Sikkim in the year 1997.
          </Paragraph>
          <Paragraph classes={`pt-2`}>
            Sikkim State Co-operative Bank Ltd (SISCO Bank Ltd) thus started its
            journey as the Apex State Co-operative Bank on 12/12/1998. Shri
            Pawan Chamling, The Honourable Chief Minister of Sikkim inaugurated
            the Bank and its first Branch at Gangtok.
          </Paragraph>
          <Paragraph classes={`pt-2`}>
            The Bank has been working with an objective of safe keeping of
            deposits of Sikkimese people and providing finance for all kinds of
            economic activities with special focus on financing of Agriculture
            and rural sector.
          </Paragraph>
          <Paragraph classes={`pt-2`}>
            The headquarter of the Bank is located at Metro Point, Tadong,
            Gangtok. It presently has a network of 14 branches and ATMs spread
            across the State in sync with the tag line of ‘SISCO Bank, Hamro
            Bank’ , the Bank is focused on rendering the best banking services
            to rural and financially excluded population, in addition to
            providing a host of modern technology driven products and services,
            competitively at par with other private and nationalized Banks.
          </Paragraph>
          <Paragraph classes={"pt-2"}>
            All Bank deposits are covered under the insurance scheme offered by
            Deposit Insurance and Credit Guarantee Corporation of India (DICGC)
            under DICGC Act 1961
          </Paragraph>
          <Paragraph classes={`pb-10`}>
            Aim: Strengthen the affiliates, achieve sustained growth and attain
            prime position in the banking industry.
          </Paragraph>
        </div>
      </div>
    </div>
  );
};

export default History;
