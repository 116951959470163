import React from "react";
import { useState } from "react";
import Error from "./Error";

const InputField = ({
  register,
  label,
  type,
  value,
  placeholder,
  onChange,
  setValue,
  name,
  errors,
  clearErrors,
  pattern,
  ...rest
}) => {
  const [isValid, setIsValid] = useState(true);
  const setValueHandler = (value) => {
    rest.setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <label
        className="block uppercase tracking-wide text-gray-700 text-sm md:text-base font-bold mb-2 "
        for={label}
      >
        {label} {<span className="text-red-600">*</span>}
      </label>
      <input
        {...register(name, { required: rest.required })} //required has been passed under '...rest'
        //className={`block w-full bg-gray-50 text-gray-700 focus:border-2 focus:border-green-500  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
        className={`block w-full bg-gray-50 text-gray-700 
    rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white
    ${
      errors[name]
        ? `border-2  border-red-500`
        : `focus:border-2  focus:border-green-500`
    }
    `}
        autoComplete="off"
        id={label}
        pattern={pattern}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          setValueHandler(e.target.value);
          // setValue([name], e.target.value, { shouldValidate: true });
          console.log(e.target.value);
          clearErrors(name);

          /// checking pattern for regular expression
          if (pattern !== null && pattern !== undefined) {
            if (pattern.test(e.target.value)) {
              setIsValid(true);
            } else {
              setIsValid(false);
            }
          }
        }}
      />
      {errors[name] && errors[name].type === "required" && (
        <Error message={`${label} field is required!`} />
      )}
      {!isValid && pattern !== null && (
        <Error message={`${label} is invalid`} />
      )}
    </>
  );
};

export default InputField;
