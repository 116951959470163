import React from "react";
import { useState } from "react";

import PhotosCard from "../../Reuseable-Components/PhotosCard";

import useAxios from "../../hooks/useAxios";
import PhotoViewInside from "./PhotoViewInside";
import Heading from "../../Reuseable-Components/font/Heading";

const GET_PHOTO_GALLERY = "api/photo-galleries?populate=*";

const PhotoGalleryMedia = () => {
  const [selectedPhotoTitle, setSelectedPhotoTitle] = useState("");
  const [selectedPhotoArray, setSelectedPhotoArray] = useState({});

  const { data, error, loading } = useAxios(GET_PHOTO_GALLERY);

  if (loading)
    return (
      <div className="flex items-center justify-center text-green-500 my-10">
        <div
          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  if (error) return <p>Error :(</p>;
  // console.log("Photo Gallery Data", data);

  let newArray = data.sort((a, b) => {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  });

  return (
    <div className="container mx-auto ">
      <div className="">
        {selectedPhotoTitle === "" ? (
          <>
            <Heading classes={`my-10`}> Photo Albums </Heading>
            <div className="grid lg:grid-cols-3  gap-8 font-sans ">
              {newArray?.map((photo) => (
                <PhotosCard
                  photo_title={photo?.attributes?.photo_title}
                  photo_description={photo?.attributes?.photo_description}
                  photo_image={
                    photo?.attributes?.photo_image?.data[0]?.attributes?.url
                  }
                  photos_list={photo?.attributes?.photos_list.data}
                  setSelectedPhotoArray={setSelectedPhotoArray}
                  setSelectedPhotoTitle={setSelectedPhotoTitle}
                />
              ))}
            </div>
          </>
        ) : (
          <PhotoViewInside
            photo_title={selectedPhotoTitle}
            selectedPhotoArray={selectedPhotoArray}
            setSelectedPhotoTitle={setSelectedPhotoTitle}
          />
        )}
      </div>
    </div>
  );
};

export default PhotoGalleryMedia;
