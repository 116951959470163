import React from "react";

const ErrorPage = () => {
  return (
    <div className="bg-green-800 flex flex-col items-center h-screen">
      <span className="font-extrabold text-red-600 text-7xl pb-6">Error.</span>
      <span className="font-bold text-red-600 text-5xl">
        An error occured while processing your request.
      </span>
    </div>
  );
};

export default ErrorPage;
