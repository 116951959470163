import React, { useState } from "react";
import bankLogo from "../../../Assets/bank.png";
import useAxios from "../../../hooks/useAxios";
import ReactMarkdown from "react-markdown";
import SubHeading from "../../../Reuseable-Components/font/SubHeading";
import Paragraph from "../../../Reuseable-Components/font/Paragraph";
import { NavLink } from "react-router-dom";

const GET_LOAN_SCHEMES = "api/productcategories?populate=*";

export default function LoanCategory({
  loanData,
  activeCategoryTab,
  setActiveCategoryTab,
  categoryDescription,
  setCategoryDescription,
}) {
  const activetabcss =
    "bg-green-100 rounded-md   border-[#0E381D] border-b-2 text-green-600 2xl:text-lg font-medium";
  const nonactivetabcss = "text-gray-500 2xl:text-lg font-semibold";
  const { data, error, loading } = useAxios(GET_LOAN_SCHEMES);
  // console.log("Data", data);

  const loanCategory = data?.filter(
    (productcategory) =>
      productcategory?.attributes?.checkratestype.data.attributes.typesid ===
      loanData[0]?.attributes?.typesid
  );

  let defaultActiveTab;
  if (localStorage.getItem("defaultTab")) {
    defaultActiveTab = loanCategory && loanCategory[0]?.attributes.categoryid;
  } else {
    defaultActiveTab = loanCategory && loanCategory[1]?.attributes?.categoryid;
  }

  const prdCategoryDescriptionDefault =
    loanCategory &&
    loanCategory.filter(
      (category) => category.attributes.categoryid === defaultActiveTab
    )[0].attributes.categorydescription;

  const changeTab = (id) => {
    setActiveCategoryTab(id);
    const prdCategoryDescription =
      loanCategory &&
      loanCategory.filter(
        (category) => category.attributes.categoryid === id
      )[0].attributes.categorydescription;
    setCategoryDescription(prdCategoryDescription);
  };
  // console.log("loan Cate", loanCategory);

  if (loading)
    return (
      <div className="flex items-center justify-center text-green-500 my-14">
        <div
          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  if (error) return <p>Error :(</p>;

  return (
    <div className="container mx-auto">
      <div className="flex flex-col md:flex-row md:pl-6 md:gap-4 items-center shadow-lg mt-14">
        <img src={bankLogo} alt="image not found" className="w-28 md:w-36" />

        <div className="p-6 text-justify">
          <SubHeading classes={`pt-4`}>
            {loanData[0]?.attributes?.typesname}
          </SubHeading>
          <Paragraph classes={"pt-2"}>
            <ReactMarkdown>
              {loanData[0]?.attributes?.description}
            </ReactMarkdown>
          </Paragraph>
        </div>
      </div>
      <div>
        {loanCategory?.length > 0 && (
          <div className="px-6">
            <div className="font-sans flex flex-col lg:flex-row  gap-4 py-6">
              {loanCategory.map((category, index) => (
                <div
                  key={index}
                  className={`${
                    activeCategoryTab !== undefined
                      ? category.attributes.categoryid === activeCategoryTab
                        ? activetabcss
                        : nonactivetabcss
                      : category.attributes.categoryid === defaultActiveTab
                      ? activetabcss
                      : nonactivetabcss
                  } cursor-pointer px-4 py-2`}
                  onClick={() => changeTab(category.attributes.categoryid)}
                >
                  {category.attributes.categorytitle}
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="p-6">
          {categoryDescription !== "" ? (
            <Paragraph>
              <ReactMarkdown>{categoryDescription}</ReactMarkdown>
            </Paragraph>
          ) : (
            <Paragraph>
              <ReactMarkdown>{prdCategoryDescriptionDefault}</ReactMarkdown>
            </Paragraph>
          )}
        </div>
      </div>
      <div className="p-6 text-base font-sans 2xl:text-lg font-medium">
        <button className="px-6 py-2 bg-[#C79E16] rounded-xl text-white">
          <NavLink to="/get_in_touch/request_for_service">Enquire</NavLink>
        </button>
      </div>
    </div>
  );
}
