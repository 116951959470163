import React from "react";
import ContactUs from "../../Components/ContactUs/ContactUs";
import Products from "../../Components/ProductsCard/Products";
import PublicCarousel from "../../Components/PublicCarousel/PublicCarousel";
import RequestForService from "../../Components/RequestForService/RequestForService";
import NoticeCircularsButton from "../../Reuseable-Components/Notice&CircularsButton/NoticeCircularsButton";
import PublicCarousel_v2 from "../../Components/public_carousel_v2/Public_Carousel_v2";

export default function Home({
  showHomeCarousel,
  setShowHomeCarousel,
  videoModalIsOpen,
  setVideoModalIsOpen,
}) {
  return (
    <>
      <NoticeCircularsButton
        setVideoModalIsOpen={setVideoModalIsOpen}
        setShowHomeCarousel={setShowHomeCarousel}
      />
      {/* <PublicCarousel /> */}

      <div className="block lg:hidden">
        {showHomeCarousel ? (
          <div>
            <PublicCarousel_v2 />
          </div>
        ) : null}
      </div>

      <div className="hidden lg:block">
        <PublicCarousel_v2 />
      </div>

      <RequestForService />
      <Products />
      {/* <FB_Carousel /> */}
      {/* <Blogs /> */}
      <ContactUs />
    </>
  );
}
