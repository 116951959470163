import React, { useEffect, useState } from "react";

import useAxios from "../../hooks/useAxios";
import BlogCard from "../../Reuseable-Components/BlogCard";
import BlogPageCard from "../../Reuseable-Components/BlogPageCard";
import Heading from "../../Reuseable-Components/font/Heading";

const GET_BLOG_DETAILS = "api/blog-posts?populate=*";

const Blogs = () => {
  const { data, error, loading } = useAxios(GET_BLOG_DETAILS);
  const [isDetailsActive, setIsDetailsActive] = useState(false);
  const [activeBlogDetails, setActiveBlogDetails] = useState();

  useEffect(() => {
    let showSpecficBlog = window.location.href.includes("blogs/2");

    if (showSpecficBlog) {
      console.log("Show specfic blog");
      console.log({ data });

      let blogObj = {};

      blogObj = data
        ?.filter((blog) => +blog?.id === +2)
        .map((dataObj) => setActiveBlogDetails(dataObj));

      setIsDetailsActive(true);
    }
  }, [data]);

  if (loading)
    return (
      <div className="flex items-center justify-center text-green-500 my-10">
        <div
          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  if (error) return <p>Error :(</p>;

  //   console.log("Blog Data: ", data);

  return (
    <>
      <div className="container mx-auto my-12 font-sans ">
        {isDetailsActive ? (
          <BlogCard
            data={activeBlogDetails}
            setIsDetailsActive={setIsDetailsActive}
          />
        ) : (
          <>
            <div className="">
              <Heading classes={`my-10`}>Blogs</Heading>
            </div>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 lg:gap-4 md:gap-4 gap-9">
              {data?.map((blog) => (
                <BlogPageCard
                  blog_id={blog?.attributes?.id}
                  blog_caption={blog?.attributes?.blog_caption}
                  blog_title={blog?.attributes?.blog_title}
                  blog_description={blog?.attributes?.blog_description}
                  blog_image={
                    blog?.attributes?.blog_image?.data?.attributes?.url
                  }
                  blog={blog}
                  setActiveBlogDetails={setActiveBlogDetails}
                  setIsDetailsActive={setIsDetailsActive}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Blogs;
