import React from "react";
import { FiArrowRight } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import "./card.css";
import Paragraph from "./font/Paragraph";
import SubHeading from "./font/SubHeading";

const Card = ({
  Card_Image,
  Card_Title,
  Card_Description,
  KnowMore_Link,
  onClick,
}) => {
  return (
    <div className="container mx-auto">
      <div className="  px-4 py-4 md:px-4  md:py-8 ">
        <div className="card-container rounded-lg shadow-lg  bg-white hover:border-green-600 border">
          <img
            className="rounded-t-lg  h-32  w-full "
            src={Card_Image}
            alt="Image Not Found"
          />

          <div className=" lg:h-32 ">
            <SubHeading classes={` mt-6 lg:mt-10 pl-4`}>
              {Card_Title}
            </SubHeading>
            <Paragraph classes={`mt-6 px-4 lg:my-8 `}>
              {Card_Description}
            </Paragraph>
          </div>

          <div className="flex content-start  pl-2 py-4">
            <NavLink
              to={KnowMore_Link}
              className="flex items-center gap-2 text-[#1d8649] font-sans text-sm 2xl:text-lg  pl-2"
              onClick={onClick}
            >
              Know More
              <FiArrowRight />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
