import React from "react";
import { AiFillCheckCircle } from "react-icons/ai";

const SuccessPage = ({ buttonDestination }) => {
  buttonDestination && console.log("url is", { buttonDestination });
  return (
    <>
      <div className="  mx-auto p-4  text-black font-semibold md:text-2xl text-base text-center">
        <div className="flex  justify-center mb-2">
          <AiFillCheckCircle color="green" size={60} />
        </div>
        <p>Your response has been successfully recorded.</p>
        <p>We will contact you shortly.</p>

        <div class="flex space-x-2 md:gap-60 mt-14 flex-wrap gap-3  justify-center">
          <button
            type="button"
            class="inline-block px-6 py-2  bg-blue-600 text-white font-medium text-xs md:text-lg leading-tight rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          >
            <a href={buttonDestination ? buttonDestination : "#"}>
              Submit another response
            </a>
          </button>
          <button
            type="button"
            class="inline-block px-6 py-2.5  text-grey-700 font-medium text-xs leading-tight  focus:outline-none focus:ring-0 "
          >
            <div className="flex flex-col items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <a href="/" className="text-xs md:text-lg underline">
                Go to Home
              </a>
            </div>
          </button>
        </div>
      </div>
    </>
  );
};

export default SuccessPage;
