import React from "react";

import SubHeading from "../Reuseable-Components/font/SubHeading";
import Paragraph from "../Reuseable-Components/font/Paragraph";
import ReactMarkdown from "react-markdown";

// const GET_BLOGCARD_DETAILS = "api/blog-posts?populate=*";

const BlogCard = ({ data, setIsDetailsActive }) => {
  // const { data, error, loading } = useAxios(GET_BLOGCARD_DETAILS);
  console.log("card-data", data);
  return (
    <>
      <div className="container mx-auto my-10  px-6">
        <div className="shadow-xl border border-green-500 py-4  px-2  rounded-xl   gap-4">
          {/* {data?.map((blog)=> ( */}
          <>
            <div className=" mx-auto md:flex-row md:max-w-xl rounded-lg bg-white pb-8 ">
              <img
                className=" md:h-auto object-cover md:w-full rounded-t-md md:rounded-none md:rounded-l-md"
                src={data?.attributes?.blog_image?.data?.attributes?.url}
                alt="Image  Not Found"
              />
            </div>
            <div className="mx-10">
              <div className="">
                <SubHeading classes={`flex flex-col text-center mb-4`}>
                  {data?.attributes?.blog_title}
                </SubHeading>
              </div>

              <div className="">
                <Paragraph classes={`mb-2 text-justify`}>
                  <ReactMarkdown
                    children={data?.attributes?.blog_description}
                  />
                </Paragraph>
              </div>
            </div>
          </>

          {/* // ))} */}
          {/* <NavLink to="/blogs"> */}
          <button
            className="text-blue-600 bg-white rounded px-6  pt-6 font-semibold"
            onClick={() => setIsDetailsActive(false)}
          >
            &lt; Back to Blogs
          </button>
          {/* </NavLink> */}
        </div>
      </div>
    </>
  );
};

export default BlogCard;
