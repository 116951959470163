import React from "react";
import useAxios from "../../hooks/useAxios";
import ReactMarkdown from "react-markdown";
import Paragraph from "../../Reuseable-Components/font/Paragraph";
import { NavLink } from "react-router-dom";
const GET_DEPOSITS_CATEGORY = "api/productcategories?populate=*";

export default function Deposits_category({
  depositData,
  activeCategoryTab,
  setActiveCategoryTab,
  categoryDescription,
  setCategoryDescription,
}) {
  const activetabcss =
  "bg-green-100 rounded-md   border-[#0E381D] border-b-2 text-green-600 2xl:text-lg font-medium";
  const nonactivetabcss = "text-gray-500 2xl:text-xl font-semibold";
  const { data, error, loading } = useAxios(GET_DEPOSITS_CATEGORY);

  const depositCategory = data?.filter(
    (depositschemes) =>
      depositschemes?.attributes?.checkratestype?.data?.attributes?.typesid ===
      depositData[0].attributes?.typesid
  );

  const defaultActiveTab =
    depositCategory && depositCategory[0]?.attributes.categoryid;
  // console.log("depositCategory", depositCategory);

  const defalutproductCategoryDescription =
    depositCategory &&
    depositCategory.filter(
      (category) => category.attributes.categoryid === defaultActiveTab
    )[0].attributes.categorydescription;

  const changeTab = (id) => {
    setActiveCategoryTab(id);
    const productCategoryDescription =
      depositCategory &&
      depositCategory.filter(
        (category) => category.attributes.categoryid === id
      )[0].attributes.categorydescription;
    setCategoryDescription(productCategoryDescription);
  };

  if (loading)
    return (
      <div className="flex items-center justify-center text-green-500 my-10">
        <div
          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  if (error) return <p>Error :(</p>;

  return (
    <div className="mx-auto">
      {depositCategory?.length > 0 && (
        <>
          <div className="font-sans flex  flex-col lg:flex-row gap-4 py-6">
            {depositCategory.map((category, index) => (
              <div
                key={index}
                className={`${
                  activeCategoryTab !== undefined
                    ? category.attributes.categoryid === activeCategoryTab
                      ? activetabcss
                      : nonactivetabcss
                    : category.attributes.categoryid === defaultActiveTab
                    ? activetabcss
                    : nonactivetabcss
                } cursor-pointer px-4 py-2 `}
                onClick={() => changeTab(category.attributes.categoryid)}
              >
                {category.attributes.categorytitle}
              </div>
            ))}
          </div>
          <div>
            {categoryDescription !== "" ? (
              <Paragraph>
                <ReactMarkdown>{categoryDescription}</ReactMarkdown>
              </Paragraph>
            ) : (
              <Paragraph>
                <ReactMarkdown skipHtml="">
                  {defalutproductCategoryDescription}
                </ReactMarkdown>
              </Paragraph>
            )}
          </div>
        </>
      )}
      <div className=" text-base font-sans 2xl:text-xl py-6">
        <button className="px-6 py-2 bg-[#C79E16] hover:bg-[#9d7d11]  rounded-xl text-white">
          <NavLink to="/get_in_touch/request_for_service">Enquire</NavLink>
        </button>
      </div>
    </div>
  );
}
