import React, { useState } from "react";
import Heading from "../../Reuseable-Components/font/Heading";
import Paragraph from "../../Reuseable-Components/font/Paragraph";
import { InputFieldCalc } from "./InputFieldCalc";

export default function RD() {
  const [monthlyInvestmentAmount, setMonthlyInvestmentAmount] = useState("");
  const [rDMaturityAmount, setRDMaturityAmount] = useState("");
  const [rdIntrestAmount, setRdIntrestAmount] = useState("");
  const [rateOfInterest, setRateOfInterest] = useState("");
  const [rdTenure, setRdTenure] = useState("");
  const [principalAmount, setPrincipalAmount] = useState("");

  const onChangeTenure = (val) => {
    setRdTenure(Number(val));
    let tenure = 0;
    if (parseFloat(val) !== 0) {
      const tenureString = val.toString();
      tenure = parseFloat(val);
      if (tenure < 1) {
        var tenureStringSplit = tenureString.split(".");
        var decimalNumber = parseFloat(tenureStringSplit[1]);
        if (decimalNumber == 1) {
          setRateOfInterest(5.5);
        } else if (decimalNumber >= 2 && decimalNumber <= 5) {
          setRateOfInterest(6.3);
        } else if (decimalNumber >= 6 && decimalNumber <= 11) {
          setRateOfInterest(6.5);
        } else if (decimalNumber == 12) {
          tenure = 1;
        } else if (decimalNumber > 12) {
          tenure = decimalNumber / 12;
        }
      }
      if (tenure >= 1 && tenure < 2) {
        setRateOfInterest(7);
      } else if (tenure >= 2 && tenure < 3) {
        setRateOfInterest(7.5);
      } else if (tenure >= 3 && tenure < 10) {
        setRateOfInterest(7.5);
      }
    }
  };
  const handleSubmit = (e, val) => {
    let totalMonths = 0;
    let maturityAmount = 0;
    let actualTotalMonths = 0;
    if (Number.isInteger(rdTenure)) {
      totalMonths = parseInt(rdTenure) * 12;
    } else {
      let tenureString = rdTenure.toString();
      let tenureStringSplit = tenureString.split(".");
      let decimalNumber = parseFloat(tenureStringSplit[1]);
      totalMonths = parseInt(tenureStringSplit[0]) * 12 + decimalNumber;
    }
    actualTotalMonths = totalMonths;
    let RI = rateOfInterest / 100;
    calcRDMaturity(totalMonths, actualTotalMonths, maturityAmount, RI);
  };

  function calcRDMaturity(totalMonths, actualTotalMonths, maturityAmount, RI) {
    maturityAmount = parseFloat(
      maturityAmount +
        parseFloat(monthlyInvestmentAmount) *
          Math.pow(1 + RI / 4, 4 * (parseInt(totalMonths) / 12))
    );

    totalMonths = totalMonths - 1;
    if (parseInt(totalMonths) > parseInt(0)) {
      maturityAmount =
        maturityAmount +
        calcRDMaturity(totalMonths, actualTotalMonths, maturityAmount, RI);
    } else {
      const principal = monthlyInvestmentAmount * actualTotalMonths;
      const maturity = maturityAmount;
      const interest = Math.round(maturityAmount) - principal;
      setRDMaturityAmount(Math.floor(maturity).toFixed(2));
      setRdIntrestAmount(Math.floor(interest).toFixed(2));
      setPrincipalAmount(principal).toFixed(2);
    }
  }

  const handleReset = () => {
    setPrincipalAmount(0);
    setRdIntrestAmount(0);
    setRDMaturityAmount(0);
    setMonthlyInvestmentAmount("");
    setRdTenure("");
    setRateOfInterest("");
  };

  return (
    <div className="container mx-auto font-sans ">
      {/* <h1 className="text-center  font-medium text-2xl lg:text-5xl text-[#12381f] mb-12">
        RD Calculator
      </h1> */}
      <Heading classes={`my-12`}>RD Calculator</Heading>
      <Paragraph classes={"  md:p-4 px-10"}>
        <span className="text-gray-500 font-semibold">RD Calculator </span>
        helps you calculate the maturity value (principal amount + interest
        earned) of your deposit made under the recurring deposit schemes of any
        banks in India. This Recurring deposit calculator does not account for
        TDS (Tax Deduction at Source) as different banks use varied periods for
        deduction of the TDS. That said, the recurring deposit interest
        calculator is still an excellent tool for determining the future value
        of your RD.
      </Paragraph>

      <div className="grid md:grid-cols-2 py-12 px-4">
        <div className="py-10 grid md:col-span-1 gap-y-6 shadow-2xl px-6  ">
          <form action="" className="font-body flex flex-col gap-y-6">
            <InputFieldCalc
              label=" Monthly Deposit Amount :"
              onChange={(e) =>
                setMonthlyInvestmentAmount(Number(e.target.value))
              }
              value={monthlyInvestmentAmount}
              error="** Enter Monthly Deposit Amount"
            />
            <InputFieldCalc
              label=" Deposit Tenure (Years) *"
              onChange={(e) => onChangeTenure(e.target.value)}
              value={rdTenure}
              error="** Enter Tenure (Years)"
            />
            <p className="text-gray-900 lg:text-sm text-xs font-sans font-semibold">
              **E.g. 0.1 = 1 Month, 0.10 = 10 Months, 1 = 1 Year, 1.5 = 1 year,
              5 Months
            </p>

            <InputFieldCalc
              label="Rate of Interest"
              value={rateOfInterest}
              error="** Enter Rate of Interest"
            />

            <div className="flex space-x-2 justify-start ">
              <button
                type="button"
                className=" inline-block px-4 py-2  text-sm bg-[#0E381D] hover:bg-[#228545] text-white  rounded-md 2xl:text-xl"
                onClick={handleSubmit}
              >
                Calculate
              </button>
              <button
                type="button"
                className="inline-block px-6 py-2 bg-[#C79E16] hover:bg-[#eebd1b] text-white font-medium  rounded-md text-sm 2xl:text-xl"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
            <div className="flex items center justify-between font-semibold font-sans pt-6 2xl:text-xl ">
              <p>Invested amount</p>
              <p className="text-green-900">₹ {principalAmount}</p>
            </div>
            <div className="flex items center justify-between font-semibold font-sans 2xl:text-xl ">
              <p>Estimated return</p>
              <p className="text-green-900">₹ {rdIntrestAmount}</p>
            </div>
            <div className="flex items center justify-between font-semibold font-sans 2xl:text-xl ">
              <p>Maturity amount</p>
              <p className="text-green-900">₹ {rDMaturityAmount}</p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
