  import React, { useState } from "react";
  import Heading from "../../Reuseable-Components/font/Heading";
  import Paragraph from "../../Reuseable-Components/font/Paragraph";
  import { InputFieldCalc } from "./InputFieldCalc";

  export default function EMI() {
    const [principle, setPrinciple] = useState("");
    const [emiTenure, setEmiTenure] = useState("");
    const [rateOfIntrest, setRateOfIntrest] = useState("");

    const [renderEMI, setRenderEmi] = useState("");
    const [loanAmount, setloanAmount] = useState("");
    const [totalMonth, setTotalMonth] = useState("");
    const [rate, setRate] = useState("");

    const handleReset = () => {
      setPrinciple("");
      setEmiTenure("");
      setRateOfIntrest("");

      setloanAmount("");
      setTotalMonth("");
      setRate("");
      setRenderEmi("");
    };

    const handleSubmit = (e) => {
      let EMI, RI, Tenure;
      if (!Number.isInteger(parseFloat(emiTenure))) {
        let tenureString = parseFloat(emiTenure).toString();
        let tenureStringSplit = tenureString.split(".");
        let decimalPlace = tenureStringSplit[1].length;
        let denominator = 1;
        let i = 1;
        for (i = 1; i <= decimalPlace; i++) {
          denominator = denominator * 10;
        }
        let decimalNumber = parseFloat(tenureStringSplit[1]);
        let actualMonthinYear = parseFloat(decimalNumber / 12).toFixed(4);
        if (decimalNumber < 1) {
          actualMonthinYear = (actualMonthinYear * 10) / denominator;
        }
        let divider = parseFloat(decimalNumber) / parseFloat(denominator);
        Tenure =
          parseFloat(emiTenure) -
          parseFloat(divider) +
          parseFloat(actualMonthinYear);
        Tenure = Math.floor(parseFloat(Tenure) * 12);
        RI = parseFloat(rateOfIntrest) / 12;
        RI = RI / 100;
        console.log("RATE", RI);
        console.log("Tenure", Tenure);
      } else {
        Tenure = parseFloat(emiTenure) * 12;
        RI = parseFloat(rateOfIntrest) / 12;
        RI = RI / 100;
      }
      EMI = parseFloat(
        parseFloat(parseFloat(principle) * RI * Math.pow(1 + RI, Tenure)) /
          parseFloat(Math.pow(1 + RI, Tenure) - 1)
      ).toFixed(2);
      const LoanAmount = parseFloat(principle);
      const renderEMI = EMI;
      const TotalMonth = parseFloat(Tenure);
      const Rate = parseFloat(rateOfIntrest);
      setRenderEmi(renderEMI);
      setloanAmount(LoanAmount);
      setRate(Rate);
      setTotalMonth(TotalMonth);
    };

    return (
      <div className=" container mx-auto font-sans ">
        <div className="px-6 md:px-0">
        <Heading classes={`my-12 `}>EMI Calculator</Heading>

        <Paragraph>
          <span className="text-gray-500 font-bold  2xl:text-2xl">The EMI calculator</span>{" "}
          formula is universal and can be applied to different loans. The
          variation in EMI value occurs according to the three key variables, i.e.
          the loan amount, the loan tenure and the interest rate. The EMI payment
          is directly proportional to the loan amount and interest rates, which
          implies that with increase in amount and interest rate, the EMI on the
          loan also increases. However, the EMI is inversely proportional to the
          tenure of loan, which means that though the amount of paid interest
          increases with longer tenures, but the EMI payments decrease if the loan
          is repaid over a longer emiTenure period.
        </Paragraph>

        <div className="grid lg:grid-cols-2 lg:py-12 py-6 font-body">
          <div className=" grid col-span-1 lg:gap-y-3 shadow-md lg:px-6 px-4">
            <Paragraph classes={`py-4 2xl:text-2xl`}>
              Loan Type <span className="text-red-500  text-sm 2xl:text-2xl">*</span>
            </Paragraph>
            <select
              id="Select Loan Type"
              className=" pb-4 w-3/4 lg:w-52 2xl:text-xl font-sans font-semibold"
              onChange={(e) => setRateOfIntrest(e.target.value)}
            >
              <option>Select Loan Type</option>
              <option value="12">Vehicle Loan</option>
              <option value="14">Cash Credit</option>
              <option value="12">SWAROJGAR CREDIT CARD</option>
              <option value="14">Other Microfinance activities</option>
              <option value="13">Medium-Term Non Agri Loan</option>
              <option value="12">Commercial Vehicle Loan</option>
              <option value="14">Salaried Loan (Personal)</option>
              <option value="7">Kisan Credit Card (Individual)</option>
              <option value="5">Kisan Credit Card (MPCS)</option>
              <option value="12">Medium Term Agri / Allied</option>
              <option value="13">Medium Term Non</option>
              <option value="11">Short Term Agri / Allied</option>
              <option value="12">Short Term Non</option>
              <option value="13">DEDS</option>
              <option value="13">
                Salaried Loan (Personal) (Customers having Salaried Account with
                SISCO)
              </option>
            </select>

            <div className="flex flex-col md:flex-row font-sans font-semibold ">
              <InputFieldCalc
                label=" Loan Amount*"
                onChange={(e) => setPrinciple(e.target.value)}
                value={principle}
                error="**Enter the Amount"
              />
              <InputFieldCalc
                label="Rate of Interest"
                value={rateOfIntrest}
                error="** Select the Loan Type"
              />
            </div>

            <InputFieldCalc
            className=""
              label="Loan Tenure Years *"
              onChange={(e) => {
                setEmiTenure(e.target.value);
              }}
              value={emiTenure}
              error="**Enter Loan Tenure Years"
            />

            <div className="flex space-x-2 justify-start font-sans ">
              <button
                type="button"
                className="inline-block px-4 py-2  text-base 2xl:text-xl bg-[#0E381D] hover:bg-[#228545] text-white  rounded-md"
                onClick={handleSubmit}
              >
                Calculate
              </button>

              <button
                type="button"
                className="inline-block px-6 py-2 bg-[#C79E16] hover:bg-[#eebd1b] text-white   rounded-md text-base 2xl:text-xl"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>

            <table className=" lg:w-[90%] 2xl:text-xl font-sans font-semibold">
              <tr className="">
                <td className="p-4 ">Loan Amount:</td>
                <td className="text-green-600">₹{loanAmount} </td>
              </tr>
              <tbody className="">
                <tr className=" ">
                  <td className="p-4">Rate of Interest:</td>
                  <td className="text-green-600"> {rate} %</td>
                </tr>
                <tr className="">
                  <td className="p-4">Tenure</td>
                  <td className="text-green-600"> {totalMonth} Months </td>
                </tr>
                <tr className=" ">
                  <td className="p-4">EMI</td>
                  <td className="text-green-600">₹ {renderEMI}</td>
                </tr>
                <tr className=" ">
                  <td className="p-4">Total </td>
                  <td className="text-green-600">
                    ₹ {(renderEMI * totalMonth).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        </div>
      </div>
    );
  }
