import React from "react";
import { useState } from "react";
import useAxios from "../../hooks/useAxios";
import Heading from "../../Reuseable-Components/font/Heading";
import Paragraph from "../../Reuseable-Components/font/Paragraph";
import CheckRatesTypes from "./CheckRatesTypes";

const GET_CHECK_RATES = "api/check-rates-v2s?populate=*";

export default function CheckRates() {
  const [activeTab, setActiveTab] = useState("deposit");

  const { data, error, loading } = useAxios(GET_CHECK_RATES);

  const activetab = "font-body bg-[#0E381D] text-white ";
  const nonactivetab = "font-body text-gray-600 font-semibold";

  const changeTab = (id) => {
    setActiveTab(id);
  };
  if (loading)
    return (
      <div className="flex items-center justify-center text-[#0E381D] my-10 font">
        <div
          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  if (error) return <p>Error :(</p>;
  // console.log("CheckRates", data);

  return (
    <div className=" my-10 container mx-auto">
      {/* <h1 className="text-center mb-10 font-medium text-2xl lg:text-5xl text-[#12381f]">
        Check Rates
      </h1> */}
      <Heading classes={`mb-10`}>Check Rates</Heading>

      <div className="flex flex-row gap-6 mb-6 ">
        {data.map((checkrates) => (
          <div
            className={` font-sans px-6 py-2 rounded-3xl cursor-pointer shadow-lg text-sm 2xl:text-lg ${
              checkrates.attributes.checkrateid === activeTab
                ? activetab
                : nonactivetab
            }`}
            onClick={() => changeTab(checkrates.attributes.checkrateid)}
          >
            {checkrates.attributes.checkratename}
          </div>
        ))}
      </div>

      <CheckRatesTypes checkRateType={activeTab} />
    </div>
  );
}
