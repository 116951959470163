import React from "react";
import annualreportpdf from "../../Assets/AnnualReport2018_19.pdf";
import Heading from "../../Reuseable-Components/font/Heading";

const AnnualReport = () => {
  return (
    <div className="container mx-auto ">
      <div className="lg:mx-20 my-10 mx-10">
        {/* <h1 className="text-center my-20 font-medium text-2xl lg:text-5xl text-[#12381f]">
          Annual Report 2018-2019
        </h1> */}
        <Heading>Annual Report 2018-2019</Heading>

        <div className="h-screen pt-8">
          <object
            className=""
            height="100%"
            width="100%"
            type="application/pdf"
            data={annualreportpdf}
          >
            <p>
              Your browser does not support PDFs.
              <a href={annualreportpdf}>click here to download the PDF file.</a>
            </p>
          </object>
        </div>
      </div>
    </div>
  );
};

export default AnnualReport;
