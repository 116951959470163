import React from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import useAxios from "../../hooks/useAxios";
import Paragraph from "../../Reuseable-Components/font/Paragraph";
import CheckRateSchemes from "./CheckRateSchemes";

const activetab = "border-[#0E381D] border-b-4 text-green-600";
const nonactivetab = "text-gray-600 font-semibold";
const GET_CHECK_RATES_TYPES = "api/checkratestypes?populate=*";

export default function CheckRatesTypes({ checkRateType }) {
  const [activeTab, setActiveTab] = useState(1);
  const { data, error, loading } = useAxios(GET_CHECK_RATES_TYPES);
  const checkRateData = data?.filter(
    (ratetype) =>
      ratetype.attributes.check_rates_v_2.data.attributes.checkrateid ===
      checkRateType
  );
  // checkRateData &&
  //   console.log("checkRateData", Object?.keys(checkRateData)?.length);

  const changeTab = (id) => {
    setActiveTab(id);
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      checkRateData &&
        Object.keys(checkRateData).length > 0 &&
        setActiveTab(checkRateData[0]?.attributes?.typesid);
    }

    return () => {
      isMounted = false;
    };
  }, [checkRateType]);

  return (
    <div className="px-6">
      {checkRateData?.length > 0 && (
        <div class="flex flex-col lg:flex-row gap-6 font-body">
          {checkRateData.map((data) => (
            <div
              className={`px-6 py-2 bg-white cursor-pointer shadow border text-sm 2xl:text-lg font-sans ${
                data.attributes.typesid === activeTab ? activetab : nonactivetab
              }`}
              onClick={() => changeTab(data.attributes.typesid)}
            >
              <Paragraph>{data.attributes.typesname}</Paragraph>
            </div>
          ))}
        </div>
      )}
      <CheckRateSchemes checkRateScheme={activeTab} />
    </div>
  );
}
