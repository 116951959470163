import React from "react";
import SubHeading from "../Reuseable-Components/font/SubHeading";
import Paragraph from "../Reuseable-Components/font/Paragraph";

const AwardsCard = ({
  award_image,
  award_year,
  award_title,
  award_description,
}) => {
  return (
    <div className="lg:px-6">
      <div className="flex flex-col border-2 py-4  px-2  rounded-xl   gap-4">
        <div className="flex justify-center md:flex-row md:max-w-xl rounded-lg bg-white  ">
          <img
            className="  md:h-auto object-cover md:w-72 rounded-t-md md:rounded-none md:rounded-l-md"
            src={award_image}
            alt="Image  Not Found"
          />
        </div>

        <div className="flex justify-center">
          <SubHeading classes={`flex flex-col text-center mb-4`}>
            {award_title}
          </SubHeading>
        </div>

        <div className=" text-center">
          <Paragraph>{award_description}</Paragraph>
        </div>

        <div className="flex justify-center">
          <p className="text-white font-sans text-lg bg-[#22C55E] rounded-lg px-6 py-2.5">
            {award_year}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AwardsCard;
