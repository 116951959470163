import React from "react";
import { NavLink } from "react-router-dom";
import rightArrow from "../../Assets/rightArrow.png";
const RequestForService = () => {
  return (
    <>
      {/* <div className="lg:hidden flex flex-col gap-3.5 px-6 pb-16 pt-12">
        <h1 className="text-[#0E381D] font-sans font-semibold text-xl  ">
          Happy today. <br />
          Happy tomorrow.
        </h1>
        <p className="text-gray-900 font-sans text-sm leading-7 py-3">
          For children below the age of 10, Dakshina account allows your little
          ones to become primary account holders under your guardianship.It also
          offers an amount of 1 Lakh as education insurance cover in case of
          death of parent/guardian. Allowing you zero minimum balance, you can
          deposit your dakshina for your child with zero hassles as well.
        </p>

        <div className="py-2">
          <NavLink to="/get_in_touch/request_for_service">
            <span className=" text-base bg-[#C79E16] rounded-sm text-white font-sans  font-medium px-6 py-2 hover:bg-[#bf9711] z-100 hover:underline underline-offset-2 cursor-pointer">
              Apply Now
            </span>
          </NavLink>
        </div>
      </div> */}

      <div className="bg-[#0E381D]   px-3 py-4 lg:px-auto lg:py-12 flex justify-center items-center">
        <div>
          <h1 className="text-white  font-sans  text-center pt-2 text-xs lg:text-xl 2xl:text-2xl px-2 py-3">
            Request for a Service with us like Opening an account, Loans, etc
            and our executives will get back to you via phone or mail.
          </h1>
          <div className="text-center py-4 lg:py-10">
            <NavLink to="/get_in_touch/request_for_service">
              <span
                className="text-sm font-sans font-medium   px-4 py-1 md:px-10 md:py-2 bg-white
             text-[#158F44] rounded-full items-center hover:bg-green-50 inline-flex gap-2
             lg:text-xl 2xl:text-2xl"
              >
                Request for Service
                <img
                  src={rightArrow}
                  alt="Image Not Found"
                  className=" h-6 lg:h-12 "
                />
              </span>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestForService;
