import React from "react";
import underConstruction from "../../Assets/underConstruction.svg";
import Paragraph from "../../Reuseable-Components/font/Paragraph";
import SubHeading from "../../Reuseable-Components/font/SubHeading";
const Faq = () => {
  return (
    <div className="continer mx-auto flex flex-col   my-20 gap-4">
      <div className="flex justify-center pb-10">
        <img
          src={underConstruction}
          alt="Image Not Found"
          className=" w-48 lg:w-96 "
        />
      </div>
      <SubHeading classes={`text-center`}>
        FAQs Details to be uploaded soon..
      </SubHeading>
    </div>
  );
};

export default Faq;
