import React, { useState } from "react";
import useAxios from "../../hooks/useAxios";
import { useEffect } from "react";

const GET_CHECK_RATES_SCHEMES = "api/checkrateschemes?_sort=id:asc&populate=*";

export default function CheckRateSchemes({ checkRateScheme }) {
  const { data, error, loading } = useAxios(GET_CHECK_RATES_SCHEMES);
  const checkRateSchemesData = data?.filter(
    (schemetype) =>
      schemetype.attributes.checkratestype.data.attributes.typesid ===
      checkRateScheme
  );

  const [sequencedOrdered, setSequencedOrdered] = useState();

  useEffect(() => {
    console.log({ checkRateSchemesData });

    let seqOrdered = checkRateSchemesData;

    seqOrdered = seqOrdered?.sort((a, b) => a.id - b.id);

    setSequencedOrdered(seqOrdered);

    console.log({ sequencedOrdered: seqOrdered });
  }, [checkRateSchemesData]);

  if (loading)
    return (
      <div className="flex items-center justify-center text-green-500 my-10">
        <div
          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  if (error) return <p>Error :( </p>;

  return (
    <div className="font-sans font-semibold">
      {checkRateScheme === 2 ? (
        <>
          <div class="grid grid-cols-3 gap-4 md:w-3/4 bg-gray-300 text-gray-800 mt-6 ">
            <div class="col-span-1 border-r border-white p-3 text-sm  2xl:text-lg font-bold">
              {/* {checkRateScheme === 2 ? "Term Deposit" : "Scheme"} */}
              Deposit Period
            </div>

            {checkRateScheme === 2 ? (
              <>
                <div class="col-span-1 border-r border-white p-3 text-sm  2xl:text-lg font-bold">
                  Interest Rate(% p.a) Without Premature Closure Facility
                </div>
                <div class="col-span-1 py-3 text-sm 2xl:text-lg font-bold ">
                  Interest Rate(% p.a) With Premature Closure Facility
                </div>
              </>
            ) : (
              <>
                <div class="col-span-1 border-r border-white p-3 text-sm  2xl:text-lg font-bold">
                  Interest Rate
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <div class="grid grid-cols-2 gap-4 md:w-3/4 bg-gray-300 text-gray-800 mt-6 ">
            <div class="col-span-1 border-r border-white p-3 text-sm  2xl:text-lg font-bold">
              {/* {checkRateScheme === 2 ? "Term Deposit" : "Scheme"} */}
              Scheme
            </div>

            <div class="col-span-1 border-r border-white p-3 text-sm  2xl:text-lg font-bold">
              Interest Rate
            </div>
          </div>
        </>
      )}

      {checkRateScheme === 2
        ? sequencedOrdered &&
          sequencedOrdered.map((data) => (
            <div class="grid grid-cols-3 gap-4 md:w-3/4">
              <div class="col-span-1 border-r border-gray-200 p-3 overflow-scroll md:overflow-auto text-sm 2xl:text-lg">
                {data.attributes.interesttype}
              </div>

              <>
                <div class="col-span-1 border-r border-gray-200 p-3 overflow-scroll md:overflow-auto text-sm 2xl:text-lg">
                  {data?.attributes?.interestrate_without_closure}
                </div>
                <div class="text-sm  2xl:text-lg col-span-1 py-3">
                  {data?.attributes?.interestrate_with_clousre}
                </div>
              </>
            </div>
          ))
        : sequencedOrdered &&
          sequencedOrdered.map((data) => (
            <div class="grid grid-cols-2 gap-4 md:w-3/4">
              <div class="col-span-1 border-r border-gray-200 p-3 overflow-scroll md:overflow-auto text-sm 2xl:text-lg">
                {data?.attributes?.interesttype}
              </div>

              <div class="col-span-1 border-r border-gray-200 p-3 overflow-scroll md:overflow-auto text-sm 2xl:text-lg">
                {data?.attributes?.interestrate}
              </div>
            </div>
          ))}
    </div>
  );
}
