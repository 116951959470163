/* eslint-disable react/jsx-no-undef */
import React, { useEffect } from "react";
import Heading from "../Reuseable-Components/font/Heading";
import { Link } from "react-router-dom";

export default function DeafAccounts() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className=" h-screen  ">
      <Heading classes={"py-10 underline  underline-offset-2"}>
        Deaf Accounts
      </Heading>

      <div className="hidden md:block md:mx-20 md:my-4">
        <Link to="https://files.siscobank.com/deaf_Account.pdf" target="_blank">
          <div className=" flex items-center gap-2 cursor-pointer ">
            <span>1.</span>
            <p className="text-[#0e381d] font-medium underline underline-offset-2 hover:text-blue-500  ">
              Deaf Account
            </p>
            <span className="italic text-sm text-blue-800">
              (click to download)
            </span>
          </div>
        </Link>

        <Link
          to="https://files.siscobank.com/DEAF%20FEBRUARY.xlsx.pdf"
          target="_blank"
        >
          <div className="flex items-center gap-2 cursor-pointer pt-4 ">
            <span>2.</span>
            <p className="text-[#0e381d] font-medium underline underline-offset-2 hover:text-blue-500   ">
              Deaf Account (February)
            </p>
            <span className="italic text-sm text-blue-800">
              (click to download)
            </span>
          </div>
        </Link>
      </div>

      <div className="block md:hidden mx-6">
        <Link to="https://files.siscobank.com/deaf_Account.pdf" target="_blank">
          <div className=" flex  gap-2 cursor-pointer ">
            <span>1.</span>
            <p className="text-[#0e381d] font-medium underline underline-offset-2 hover:text-blue-500  ">
              Deaf Account
              <span className="italic text-sm text-blue-800 ">
                (click to download)
              </span>
            </p>
          </div>
        </Link>

        <Link
          to="https://files.siscobank.com/DEAF%20FEBRUARY.xlsx.pdf"
          target="_blank"
        >
          <div className=" flex  gap-2 cursor-pointer pt-4 ">
            <span>2.</span>
            <p className="text-[#0e381d] font-medium underline underline-offset-2 hover:text-blue-500  ">
              Deaf Account (February)
              <span className="italic text-sm text-blue-800 ">
                (click to download)
              </span>
            </p>
          </div>
        </Link>
      </div>
    </section>
  );
}
