import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import InputDropDown from "../../Reuseable-Components/InputDropDown";
import Heading from "../../Reuseable-Components/font/Heading";
import SubHeading from "../../Reuseable-Components/font/SubHeading";

import { fiscal_year } from "../utilities/constant/inputdropdown";

import annualreportarchivepdf from "../../Assets/Annual_Report_Archives_2018_2019.pdf";
import annualreportarchivepdf_2022_2023 from "../../Assets/Annual_Report_Archives_2022_2023.pdf";

import annualreportarchivepdf1 from "../../Assets/annualReport_Images/2018_19/page1.jpg";
import annualreportarchivepdf2 from "../../Assets/annualReport_Images/2018_19/page2.jpg";
import annualreportarchivepdf3 from "../../Assets/annualReport_Images/2018_19/page3.jpg";
import annualreportarchivepdf4 from "../../Assets/annualReport_Images/2018_19/page4.jpg";
import annualreportarchivepdf5 from "../../Assets/annualReport_Images/2018_19/page5.jpg";
import annualreportarchivepdf6 from "../../Assets/annualReport_Images/2018_19/page6.jpg";

import annualreportarchivepdf22_23_page1 from "../../Assets/annualReport_Images/2022_23/annualreport_2022_23(1).jpeg";
import annualreportarchivepdf22_23_page2 from "../../Assets/annualReport_Images/2022_23/annualreport_2022_23(2).jpeg";

const AnnualReportsArchive = () => {
  const [selectedFiscalYear, setSelectedFiscalYear] = useState({});

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();

  let imgArray2018_19 = [
    {
      img: { annualreportarchivepdf1 },
    },
    {
      img: { annualreportarchivepdf2 },
    },
    {
      img: { annualreportarchivepdf3 },
    },
    {
      img: { annualreportarchivepdf4 },
    },
    {
      img: { annualreportarchivepdf5 },
    },
    {
      img: { annualreportarchivepdf6 },
    },
  ];

  return (
    <div className=" container mx-auto font-sans">
      <div className="lg:mx-0   mx-10">
        {/* <h1 className="text-center  font-medium text-2xl lg:text-5xl text-[#12381f]">
          Annual Reports Archive
        </h1> */}
        <Heading classes={`my-10`}>Annual Reports Archive</Heading>
        <div className="pb-4">
          {/* <span className=" font-serif font-semibold text-gray-500 text-lg  mb-2">
           
          </span> */}
          <SubHeading classes={`mb-2`}>
            {" "}
            Please select a fiscal year to view the report.
          </SubHeading>
        </div>

        <InputDropDown
          setDropdownValue={setSelectedFiscalYear}
          register={register}
          required={false}
          label="Select Fiscal Year"
          classes={`rounded text-sm  w-full`}
          onChange={null}
          data={fiscal_year}
          name="fiscal_year"
          errors={errors}
          clearErrors={clearErrors}
        />

        <div className="hidden md:block my-12">
          {selectedFiscalYear?.value === "2018-2019" && (
            <div className="h-screen">
              <object
                className=""
                height="100%"
                width="100%"
                type="application/pdf"
                data={annualreportarchivepdf}
              ></object>
            </div>
          )}
          {selectedFiscalYear?.value === "2022-2023" && (
            <div className="h-screen">
              <object
                className=""
                height="100%"
                width="100%"
                type="application/pdf"
                data={annualreportarchivepdf_2022_2023}
              ></object>
            </div>
          )}
        </div>

        <div className="block md:hidden my-4">
          {selectedFiscalYear?.value === "2018-2019" && (
            <>
              <div className="flex flex-col">
                <img src={annualreportarchivepdf1} alt="" />
                <img src={annualreportarchivepdf2} alt="" />
                <img src={annualreportarchivepdf3} alt="" />
                <img src={annualreportarchivepdf4} alt="" />
                <img src={annualreportarchivepdf5} alt="" />
                <img src={annualreportarchivepdf6} alt="" />
              </div>

              <div className="py-2 flex justify-center">
                <a href={annualreportarchivepdf} target="_blank" download>
                  <div className="px-3 py-1 bg-[#0E381D] text-white cursor-pointer rounded-sm">
                    Download PDF
                  </div>
                </a>
              </div>
            </>
          )}
          {selectedFiscalYear?.value === "2022-2023" && (
            <>
              <div className="flex flex-col ">
                <img src={annualreportarchivepdf22_23_page1} alt="" />
                <img src={annualreportarchivepdf22_23_page2} alt="" />
              </div>

              <div className="py-2 flex justify-center">
                <a
                  href={annualreportarchivepdf_2022_2023}
                  target="_blank"
                  download
                >
                  <div className="px-3 py-1 bg-[#0E381D] text-white cursor-pointer rounded-sm">
                    Download PDF
                  </div>
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AnnualReportsArchive;
