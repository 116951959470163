import React from "react";

export default function Heading({ classes, ...rest }) {
  return (
    <div>
      <h1
        className={`text-[#0e381d] text-center 2xl:text-6xl lg:text-4xl text-2xl font-sans font-extrabold  ${classes}`}
      >
        {rest.children}
      </h1>
    </div>
  );
}
