import React from "react";
import Paragraph from "./font/Paragraph";
import SubHeading from "./font/SubHeading";
import "./PhotosCard.css";

const PhotosCard = ({
  photo_image,
  photo_title,
  photo_description,
  photos_list,
  setSelectedPhotoArray,
  setSelectedPhotoTitle,
}) => {
  const onClickHandler = () => {
    setSelectedPhotoTitle(photo_title);
    setSelectedPhotoArray(photos_list);
  };

  return (
    <div className="container mx-auto">
      <div className="event  ">
        <div className="">
          <img
            src={photo_image}
            alt="Image Not Found"
            className="h-96 md:w-full"
            onClick={onClickHandler}
          />

          <div className="content ">
            <h3 className="lg:text-xl text-lg font-sans">{photo_title}</h3>

            <div className="rollover">
              <p className="font-sans ">{photo_description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    // <div>
    //   <a className="mb-5 relative text-center block mx-0 my-auto" href="#">
    //     <img src={photo_image} className="h-2/3" />

    //     <div className=" bg-[#272727] opacity-70 hover:h-full text-white h-16 absolute bottom-0  left-0 overflow-hidden transition-all delay-200 ease-in-out w-full ">
    //       <h3 className="font-semibold text-xl text-white m-0 pt-5 opacity-100">
    //         {photo_title}
    //       </h3>

    //       <div className="border-t-0 text-lg leading-snug px-4 py-5 opacity-70 transition-all delay-200 ease-in-out ">
    //         <p className="m-0 font-serif text-white opacity-100">
    //           {photo_description}
    //         </p>
    //       </div>
    //     </div>
    //   </a>
    // </div>
  );
};

export default PhotosCard;
