import React from "react";
import Heading from "../../Reuseable-Components/font/Heading";
import SubHeading from "../../Reuseable-Components/font/SubHeading";
import CarrersCard from "../../Reuseable-Components/CarrersCard/CarrersCard";

const Careers = () => {
  return (
    <div className="container mx-auto py-10">
      <div className="px-3">
        <Heading classes={`mb-6`}>Career </Heading>

        <div className="flex justify-center">
          <CarrersCard />
        </div>
      </div>
    </div>
  );
};

export default Careers;
