import React from "react";
import { useState } from "react";

const ContactUs = () => {
  const [isMapLoading, setIsMapLoading] = useState(true);
  const onMyFrameLoadHandler = async (e) => {
    // console.log({e})
    // console.log(e.timeStamp);
    let loadingTime = await e.timeStamp;

    if (loadingTime) {
      // setIsMapLoading(false);
      setInterval(() => {
        setIsMapLoading(false);
      }, 1000); //The syntax of setInterval is such that after an insterval of 1000ms that is 1 sec, run the side-effect provided
    } else {
      setIsMapLoading(true);
    }
  };
  return (
    <div className="pt-1 relative font-sans">
      {/* <h1 className="text-center my-20 font-medium text-2xl lg:text-5xl text-[#12381f]">
        Contact Us
      </h1> */}
      {isMapLoading && (
        <div className="flex justify-center h-[800px] w-full absolute z-50">
          <div
            className=" m-auto spinner-border animate-spin inline-block w-8 h-8 md:w-14 md:h-14 border-4 rounded-full text-green-500"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      <div className="google-map-code relative sm:overflow-x-scroll">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14180.269744540145!2d88.5950898!3d27.311078!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8547c52821041a3d!2sSISCO%20Bank%20Head%20Office!5e0!3m2!1sen!2sin!4v1664432717504!5m2!1sen!2sin"
          width="100%"
          height="800"
          frameborder="0"
          style={{ border: 0 }}
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
          onLoad={(e) => onMyFrameLoadHandler(e)}
        />
        <div className="shadow-2xl h-auto  w-3/4 lg:w-2/6  bg-slate-100 md:p-4 p-3 absolute left-0 bottom-20">
          <p className="font-bold text-gray-800  text-xs md:text-lg mb-2">
            Head Office
          </p>
          <p>
            <span className="text-xs md:text-lg">Phone- </span>
            <span className="font-semibold text-gray-800  text-xs md:text-lg mb-2">
              03592- 232008 / 231005
            </span>
          </p>
          <p>
            <span className="text-xs md:text-lg">Email- </span>
            <span className="font-semibold text-gray-800  text-xs md:text-lg mb-2">
              siscobank@yahoo.co.in
            </span>
          </p>
          <p>
            <span className="text-xs md:text-lg">Address- </span>
            <span className="font-semibold text-gray-800  text-xs md:text-lg mb-2">
              SISCO Bank Head Office Tadong, Gangtok, Sikkim 737102
            </span>
          </p>
        </div>
      </div>

      <div className="flex justify-center pb-4">
        <span className=" text-white bg-blue-600 shadow mt-6 px-3 py-1 m-2 md:px-6 md:py-2  rounded min-w-44 font-medium text-xs md:text-lg">
          For any ATM related queries, please contact : 03592-231005
        </span>
      </div>
    </div>
  );
};

export default ContactUs;
