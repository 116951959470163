import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom"; //For routing to another page
import InputField from "../../Reuseable-Components/InputField";
import InputDropDown from "../../Reuseable-Components/InputDropDown";
import InputTextArea from "../../Reuseable-Components/InputTextArea";

import { service_type } from "../utilities/constant/inputdropdown";
import { branch } from "../utilities/constant/inputdropdown";

import ReCaptcha from "../../Reuseable-Components/ReCaptcha/ReCaptcha";
import SuccessPage from "../../Reuseable-Components/SuccessPage";
import { reContact, reEmail } from "../utilities/regularexpressions";
import Heading from "../../Reuseable-Components/font/Heading";

//Basically, you have to make states for every item in the form!

const RequestService = () => {
  const [captchaSuccess, setCaptchaSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigation = useNavigate();
  const [inputValue, setInputValue] = useState({
    fullname: "",
    email_id: "",
    contact_no: "",
    address: "",
    description: "",
    account_no: "",
  });

  const [selectedServiceType, setSelectedServiceType] = useState({});
  const [selectedBranch, setSelectedBranch] = useState({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false); //Used in context of reseting the input fields after form submission [line 119]
  const [isSubmitted, setIsSubmitted] = useState(false); //For 'Submit another response' button mapping

  //For DR0P-DOWNS, MAKE SEPARATE STATES to be used in the function inside reusable dropdown component [e.g. line 31]

  const { fullname, email_id, contact_no, address, description, account_no } =
    inputValue;
  //Whichever variables you are adding using the reusable component, add it in this array

  const POST_REQUEST_FOR_SERVICE = "api/request-services";

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();

  // const goToSuccessPage = () => {
  //   navigation("/successpage");
  // };

  const onSubmit = async (data) => {
    // data.preventDefault(); is not required when we are using useForms() in React
    // let formData = {};
    // formData.name = data.name;
    // formData.email_id = data.email_id;
    // formData.contact_no = data.contact_no;
    // formData.address = data.address;
    // formData.service_type = data.service_type;
    // formData.branch = data.branch;
    // formData.description = data.description;

    // console.log({ isFormSubmitted });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}${POST_REQUEST_FOR_SERVICE}`,
        { data }
        //{ data : formData}        The payload Strapi checks on reciving these info is "data" so pass your object under data payload
        // { name: data.name }      You can pass few data values like this also
      );

      // console.log({ res });

      if (res.status === 200) {
        setIsFormSubmitted(true);
        setIsSubmitted(true);

        //<SuccessPage buttonDestination="/requestservice" />; is an error.
        //It is just like a html tag. So should be called inside the return() section.

        //goToSuccessPage();

        // goToSuccessPage();
        //<Navigate to="/successpage" replace={true} />;

        // setIsFormSubmitted((prev) => !prev);
        //If you want to use this callback function uncomment it, then comment the previous line and also remove the setInterval function [line 123]
      } else {
        setIsFormSubmitted(false);
      }
    } catch (error) {
      // console.log({ error });
      // console.error("Cannot save data", error);
    } finally {
      setCaptchaSuccess(false);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    // console.log(e);
  };

  useEffect(() => {
    let isMounted = true; //"Side-effect"

    if (isMounted) {
      setInputValue({
        fullname: "",
        email_id: "",
        contact_no: "",
        address: "",
        description: "",
        account_no: "",
      });

      reset();
      setInterval(() => {
        setIsFormSubmitted(false);
      }, 1000);
      //setInterval() [and other timer-related functions] is an in-built function.
      //We also do not need to import any specific modules to use them.

      setSelectedServiceType({});
    }

    return () => {
      //Clean-up function (Runs first, then the "side-effect" runs)
      isMounted = false;
    };
  }, [isFormSubmitted]); //The value inside the array will be responsible for when the useEffect() will be called.
  // Condition is when the value inside the array changes

  return (
    <div className="container mx-auto font-sans font-semibold">
      <div className=" lg:mx-0 my-10 px-6">
        {/* <h1 className="text-center my-20 font-medium text-2xl lg:text-5xl text-[#12381f]">
          Request for Service
        </h1> */}
        <Heading classes={`my-10`}>Request for Service</Heading>
        {!isSubmitted ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputField
              setInputValue={setInputValue} //This user-defined function of mine is a specific-case function
              //If we use the setValue property, it is a generic version performing the same functionality
              register={register}
              required={true}
              setValue={setValue} //It is a property of React Hook Forms that performs the same functionality as my 'setInputValue' function here
              //Not actually used in this scenario. Passing it under '...rest' in the InputField reusable component
              type="text" //The type of data that you will provide to the <input> tag, can be text, email, password, date, number, checkbox, file, etc.
              value={fullname} //The  value/data that you enter in the input-field e.g. Jesson George
              //Must match with the 'name' attribute written below
              placeholder="Provide your Full Name" // The text that appears inside of the input field in the background
              label="Name" //The text that appears in front of the input field
              name="fullname" //The name of the key under which the entered data in this field will be stored in the storage
              //This variable name inside the inverted commas must match with the variable in Strapi server
              //This name here must match with the assignment of 'inputValue' variable we are using in useState() hook [line 12]
              //And also with the '...register' property of React Hook Forms
              onChange={handleChange} //The value passed here is a function that will be called when there is any change detected in the input field
              //In my case here, it is not performing any specific task. Added it for any future use.
              errors={errors}
              clearErrors={clearErrors}
            />
            <InputField
              setInputValue={setInputValue}
              register={register}
              required={true}
              setValue={setValue} //Not used
              type="email"
              value={email_id}
              placeholder="Provide your Email ID"
              label="Email-Address"
              name="email_id"
              onChange={handleChange}
              errors={errors}
              clearErrors={clearErrors}
              pattern={reEmail}
            />
            <InputField
              setInputValue={setInputValue}
              register={register}
              required={true}
              setValue={setValue} //Not used
              type="number"
              value={contact_no}
              placeholder="(+ 91)"
              label="Contact-Number"
              name="contact_no"
              onChange={handleChange}
              errors={errors}
              clearErrors={clearErrors}
              pattern={reContact}
            />
            <InputField
              setInputValue={setInputValue}
              register={register}
              required={true}
              setValue={setValue} //Not used
              type="text"
              value={address}
              placeholder="Provide your Full Address"
              label="Address"
              name="address"
              onChange={handleChange}
              errors={errors}
              clearErrors={clearErrors}
            />
            <InputDropDown
              setDropdownValue={setSelectedServiceType}
              register={register}
              required={true}
              label="Select Service Type"
              classes={`rounded text-sm w-full`}
              onChange={null}
              data={service_type}
              name="service_type"
              errors={errors}
              clearErrors={clearErrors}
            />

            {(selectedServiceType.value === "Request for Cheque book" ||
              selectedServiceType.value === "Insurance Demand") && (
              <InputField
                setInputValue={setInputValue}
                register={register}
                required={true}
                setValue={setValue} //Not used
                type="text"
                value={account_no}
                placeholder="Provide your Account Number"
                label="Account-Number"
                name="account_no"
                onChange={handleChange}
                errors={errors}
                clearErrors={clearErrors}
              />
            )}

            <InputDropDown
              setDropdownValue={setSelectedBranch}
              register={register}
              required={true}
              label="Select Branch"
              classes={`rounded text-sm w-full`}
              onChange={null}
              data={branch}
              name="branch"
              errors={errors}
              clearErrors={clearErrors}
            />
            <InputTextArea
              setInputValue={setInputValue}
              register={register}
              required={true}
              setValue={setValue} //Not used
              value={description}
              placeholder="Provide description (in less than 250 words)"
              label="Description"
              name="description"
              onChange={handleChange}
              errors={errors}
              clearErrors={clearErrors}
            />

            <div className="flex justify-start">
              {loading ? (
                <button
                  type="button"
                  className="text-white bg-primary shadow mt-6 px-6 py-2 rounded bg-opacity-70 cursor-not-allowed flex items-center"
                  disabled=""
                >
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Processing...
                </button>
              ) : (
                <div className="flex flex-col mt-3 font-medium">
                  <ReCaptcha setCaptchaSuccess={setCaptchaSuccess} />
                  <button
                    type="submit"
                    className={
                      captchaSuccess
                        ? `text-white bg-blue-600 shadow mt-6 px-3 py-1 md:px-6 md:py-2 rounded hover:bg-blue-800 min-w-44`
                        : `disabled cursor-not-allowed text-white bg-gray-500 shadow mt-6  px-3 py-1 md:px-6 md:py-2 rounded min-w-44`
                    }
                    disabled={!captchaSuccess}
                  >
                    Send Service Request
                  </button>
                </div>
              )}
            </div>
          </form>
        ) : (
          <SuccessPage buttonDestination="/requestservice" />
        )}
      </div>
    </div>
  );
};

export default RequestService;
