import React from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./carousel.css";
import BannerTab1 from "../../Assets/NewSiscoImg.webp";
import BannerMobile1 from "../../Assets/NewSiscoImg.webp";
import BannerDesktop1 from "../../Assets/NewSiscoImg.webp";
import BannerDesktop2 from "../../Assets/BannerDesktop2.webp";
import BannerTab2 from "../../Assets/BannerTab2.webp";
// import BannerTab3 from "../../Assets/768x300.jpg";
import BannerTab4 from "../../Assets/Dakshina_768x300.jpg";
import BannerTab5 from "../../Assets/community_768x300.png";
import BannerMobile2 from "../../Assets/BannerMobile2.webp";
// import BannerMobile3 from "../../Assets/400x200.jpg";
import BannerMobile4 from "../../Assets/Dakshina_400x200.jpg";
import BannerMobile5 from "../../Assets/community_400x200.png";
import BannerVideo from "../../Assets/BannerVideo.m4v";
// import BannerDesktop3 from "../../Assets/1600x433.jpg";
import BannerDesktop4 from "../../Assets/Dakshina_1600x433.jpg";
import BannerDesktop5 from "../../Assets/community_1600x433.png";
import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useEffect } from "react";

export default function PublicCarousel_v2() {
  const [carouselArray, setCarouselArray] = useState([
    BannerDesktop1,
    BannerDesktop2,
    // BannerDesktop3,
    BannerDesktop4,
    BannerDesktop5,
    BannerVideo,
  ]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState("left");

  let videoIndex = +carouselArray.length - 1;

  const slideVariants = {
    hiddenRight: {
      x: "100%",
    },
    hiddenLeft: {
      x: "-100%",
    },
    visible: {
      x: "0",
      opacity: 1,
      transition: {
        duration: 1,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  const handleNext = () => {
    setDirection("right");
    setCurrentIndex((prevIndex) =>
      prevIndex + 1 === carouselArray.length ? 0 : prevIndex + 1
    );
  };
  const handlePrevious = () => {
    setDirection("left");
    setCurrentIndex((prevIndex) =>
      prevIndex - 1 < 0 ? carouselArray.length - 1 : prevIndex - 1
    );
  };
  const handleDotClick = (index) => {
    setDirection(index > currentIndex ? "right" : "left");
    setCurrentIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 9000);

    return () => {
      clearInterval(interval);
    };
  }, [currentIndex]);

  useEffect(() => {
    let isMounted = true;
    const images = [
      BannerDesktop1,
      BannerDesktop2,
      // BannerDesktop3,
      BannerDesktop4,
      BannerDesktop5,
      BannerVideo,
    ];
    const images_tab = [
      BannerDesktop1,
      BannerDesktop2,
      // BannerTab3,
      BannerTab4,
      BannerTab5,
      BannerVideo,
    ];
    const images_phone = [
      BannerDesktop1,
      BannerDesktop2,
      // BannerMobile3,
      BannerMobile4,
      BannerMobile5,
      BannerVideo,
    ];
    let windowWidth = window.innerWidth;

    if (windowWidth <= 425) {
      setCarouselArray([...images_phone]);
    } else if (windowWidth <= 768) {
      setCarouselArray([...images_tab]);
    } else {
      setCarouselArray([...images]);
    }

    return () => {
      isMounted = false;
    };
  }, [window.innerWidth]);

  return (
    <div className="z-50">
      <div className="carousel relative  md:min-h-[200px]">
        {+videoIndex === +currentIndex ? (
          <div className="carousel-item relative float-left w-full">
            <video className="w-full  " autoPlay loop muted>
              <source src={BannerVideo} />
            </video>
          </div>
        ) : (
          <div className=" slide_direction">
            <AnimatePresence>
              <motion.img
                key={currentIndex}
                src={carouselArray[currentIndex]}
                variants={slideVariants}
                initial={direction === "right" ? "hiddenRight" : "hiddenLeft"}
                animate="visible"
                exit="exit"
              />
            </AnimatePresence>
          </div>
        )}

        <div className="hidden  lg:block">
          <div className="flex justify-between w-full px-9 absolute top-1/2">
            <div
              className=" carousel-control-prev-icon1 cursor-pointer"
              onClick={handlePrevious}
            ></div>
            <div
              className=" carousel-control-next-icon1 cursor-pointer"
              onClick={handleNext}
            ></div>
          </div>
        </div>

        {/* <div className="block md:hidden ">
          <div className="flex justify-between w-full px-9 absolute top-[30%]">
            <div
              className=" carousel-control-prev-icon1"
              onClick={handlePrevious}
            ></div>
            <div
              className=" carousel-control-next-icon1"
              onClick={handleNext}
            ></div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
