import React from "react";
import { NavLink } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import Heading from "../../Reuseable-Components/font/Heading";

const GET_MEMBERSHIP_DETAILS = "api/membership-details";

const Membership = () => {
  const { data, error, loading } = useAxios(GET_MEMBERSHIP_DETAILS);
  if (loading)
    return (
      <div className="flex items-center justify-center text-green-500 my-10">
        <div
          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  if (error) return <p>Error :(</p>;
  // console.log("Membership Details Table data- ", data);

  return (
    <div className="lg:mx-auto ">
      <div className="lg:mx-36  my-10 mx-10">
        {/* <h1 className="text-center my-20 font-medium text-2xl lg:text-5xl text-[#12381f]">
          Membership
        </h1> */}
        <Heading classes={`my-10`}>Membership</Heading>
        <div className="pb-4 font-body">
          <span className="flex justify-end font-sans font-semibold text-gray-500 text-sm md:text-lg  mb-2">
            **As on 31st March, 2019
          </span>
        </div>
        <br />

        <div>
          <div class="flex flex-col font-sans font-semibold">
            <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                <div class="overflow-hidden sm:overflow-scroll">
                  <table class="min-w-full">
                    <thead class="">
                      <tr>
                        <th
                          scope="col"
                          class="text-md md:text-xl font-bold text-gray-900 px-6 py-4 text-left border-r border-l border-t border-black"
                        >
                          Members
                        </th>
                        <th
                          scope="col"
                          class="text-md md:text-xl font-bold text-gray-900 px-6 py-4 text-left border-r border-t border-black"
                        >
                          No. of Shares Held
                        </th>
                        <th
                          scope="col"
                          class="text-md md:text-xl font-bold text-gray-900 px-6 py-4 text-left border-r border-t border-black"
                        >
                          No. of Members
                        </th>
                        <th
                          scope="col"
                          class="text-md md:text-xl font-bold text-gray-900 px-6 py-4 text-left border-r border-t border-black"
                        >
                          Amount (lakhs)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((member) => (
                        <tr>
                          <td class="px-6 py-4 whitespace-nowrap text-md md:text-lg font-sans font-semibold text-gray-900 border-x border-y border-black">
                            {member?.attributes?.members}
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap text-md md:text-lg font-sans font-semibold text-gray-900 border-x  border-y border-black">
                            {member?.attributes?.no_of_shares_held}
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap text-md md:text-lg font-sans font-semibold text-gray-900 border-x border-y border-black">
                            {member?.attributes?.no_of_members}
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap text-md md:text-lg font-sans font-semibold text-gray-900 border-x border-y border-black">
                            {member?.attributes?.amount}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="flex justify-center">
          <NavLink to="/about_us/membership/request_membership">
            <button className="text-white  font-sans text-md md:text-xl bg-[#22C55E] hover:bg-green-600 rounded-md md:rounded-lg px-3 py-1 md:px-6 md:py-2.5">
              Request Membership
            </button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Membership;
