import React from "react";
import Error from "./Error";

const InputDropDown = ({
  label,
  register,
  name,
  iserror,
  errormsg,
  onChange,
  data, //Was sending 'value' in input and textarea reusable components. Here, I'm sending data
  setDropdownValue,
  errors,
  clearErrors,
  ...rest
}) => {
  return (
    <div>
      <label
        className="block uppercase tracking-wide text-gray-700 text-base font-bold mb-2"
        for={label}
      >
        {label} {<span className="text-red-600">*</span>}
        {/* required && */}
      </label>
      <select
        {...register(name, { required: rest.required })} //required has been passed under '...rest'
        //className={`block w-full bg-gray-50 text-gray-700 focus:border-2 focus:border-green-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
        className={`block w-full bg-gray-50 text-gray-700 
    rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white
    ${
      errors[name]
        ? `border-2  border-red-500`
        : `focus:border-2  focus:border-green-500`
    }
    `}
        id={label}
        onChange={(e) => {
          setDropdownValue({
            value: e.target.value,
            label: e.target.value,
          });
          clearErrors(name);
        }}
      >
        <option value="">Select</option>
        {data?.map((item) => (
          <option value={item.value}>{item.label}</option>
        ))}
      </select>
      {/* {iserror && <Error message={errormsg} />} */}
      {errors[name] && errors[name].type === "required" && (
        <Error message={`${label} field is required!`} />
      )}
    </div>
  );
};

export default InputDropDown;
