import React from "react";
import { NavLink } from "react-router-dom";
import Heading from "../../Reuseable-Components/font/Heading";
import Paragraph from "../../Reuseable-Components/font/Paragraph";
import SubHeading from "../../Reuseable-Components/font/SubHeading";

export default function CashKendraDetails() {
  return (
    <div className="container mx-auto">
      <Heading classes={`my-10`}>CASH KENDRA Details</Heading>

      <div className="mx-6 lg:mx-20 px-10 shadow-2xl border">
        <SubHeading classes={`pt-14 text-center`}> CASH KENDRA</SubHeading>
        <p className="text-center text-xs 2xl:text-base pb-6">07 December 2018</p>
        <hr />
        <Paragraph classes={`text-sm 2xl:text-lg py-16 `}>
          Cash Kendra was Started in various MPCS across the state
        </Paragraph>
      </div>
      <div className="mx-20 px-10 py-10 ">
        <NavLink to="/financial_inclusion">
          <span className=" bg-[#C79E16] px-6 py-2 text-white rounded-lg hover:underline 2xl:text-lg">
            Back to List
          </span>
        </NavLink>
      </div>
    </div>
  );
}
