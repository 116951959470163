import React from "react";
import { RiFileSearchFill } from "react-icons/ri";
import { BiSearchAlt2 } from "react-icons/bi";
import useAxios from "../../hooks/useAxios";
import Heading from "../../Reuseable-Components/font/Heading";
import SubHeading from "../../Reuseable-Components/font/SubHeading";
import { NavLink } from "react-router-dom";
const GET_FINANCIAL_INCLUSION_INCORPORATE =
  "api/financial-inclusions-incorporateds?populate=*";

export default function () {
  const { data, error, loading } = useAxios(
    GET_FINANCIAL_INCLUSION_INCORPORATE
  );

  if (loading)
    return (
      <div className="flex items-center justify-center text-[#0E381D] my-10">
        <div
          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  if (error) return <p>Error :(</p>;
  //   console.log("Financial Inclusion Incorporate", data);
  return (
    <div className="container mx-auto ">
      <div className="px-6 lg:px-0">
      <SubHeading classes={`pt-2`}>Financial Inclusion Incorporated</SubHeading>
      <div className="flex  justify-between  pt-4 2xl:text-lg">
        <div className=" flex items-center">
          <label for="10" className="font-sans font-semibold">
            Show &nbsp;
            <select id="10" className="border">
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="audi">100</option>
            </select>
            &nbsp;entries
          </label>
        </div>

        <div className="flex items-center ">
          <label className="font-sans font-semibold">Search</label>
          <BiSearchAlt2
            className="relative top-0 left-2/3"
            size={24}
            color="#0E381D"
          />
          <input
            type="text"
            className="border border-solid border-[#0E381D]  rounded-md py-1 cursor-pointer"
          />
        </div>
      </div>

      <div class="grid grid-cols-2 gap-4 w-full bg-gray-300 text-gray-800 font-sans font-semibold mt-6 text-center ">
        <div class="col-span-1 border-r border-white p-3 text-sm 2xl:text-lg">Title</div>
        <div class="col-span-1 py-3 text-sm 2xl:text-lg">Actions</div>
      </div>

      {data.map((services) => (
        <div className="contianer mx-auto">
          <div class="grid grid-cols-2 gap-4 w-full">
            <div class="font-sans font-semibold col-span-1 border-r border-gray-200 p-3 text-center  border-b-2 text-sm 2xl:text-lg">
              {services?.attributes?.FinancialInclusions_Incorporated_Services}
            </div>
            <div class="font-sans  col-span-1 py-3 text-center border-b-2 flex justify-center">
              <NavLink to="/financial_inclusion/cashkendra_details">
                <span className="bg-[#C79E16] rounded-lg text-white  px-2  py-2 flex items-center gap-2 text-xs 2xl:text-lg">
                  {
                    services.attributes
                      .FinancialInclusion_IncorporatdServices_Action
                  }
                </span>
              </NavLink>
            </div>
          </div>
        </div>
      ))}

      <div className="flex justify-between pt-4 text-sm 2xl:text-lg font-sans font-semibold">
        <p>Showing 1 to 1 of 1 entries</p>
        <div className="flex gap-2 border-2 ">
          <p className=" px-2 py-2 text-sm 2xl:text-lg">Previous</p>
          <p className="bg-[#0E381D] px-2 py-2 text-white rounded-sm 2xl:text-lg">1</p>
          <p className="px-2 py-2 text-sm 2xl:text-lg">Next</p>
        </div>
      </div>
      </div>
    </div>
  );
}
