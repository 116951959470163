import React, { useState } from "react";
import useAxios from "../../hooks/useAxios";
import Heading from "../../Reuseable-Components/font/Heading";
import LoanCategory from "./LoansCategory/LoanCategory";
const GET_LOANS = "api/checkratestypes?populate=*";

export default function Loans() {
  const [activeTab, setActiveTab] = useState(5);
  const [loanData, setLoanData] = useState([]);
  const [activeCategoryTab, setActiveCategoryTab] = useState();
  const [categoryDescription, setCategoryDescription] = useState("");

  const activetab = "bg-[#0E381D] text-white text-sm 2xl:text-lg font-medium ";
  const nonactivetab = "text-gray-600 2xl:text-lg font-semibold ";

  const { data, error, loading } = useAxios(GET_LOANS);

  const LoanTypes = data?.filter(
    (loantypes) =>
      loantypes?.attributes?.check_rates_v_2?.data.attributes?.checkrateid ===
      "loan"
  );
  const defaultData = LoanTypes?.filter((loantypes) => loantypes?.id === 5);

  const changeTab = (id) => {
    setActiveTab(id);
    const loandata = LoanTypes?.filter((loantypes) => loantypes?.id === id);
    setLoanData(loandata);
    setActiveCategoryTab();
    setCategoryDescription("");
  };

  if (loading)
    return (
      <div className="flex items-center justify-center text-green-500 my-10">
        <div
          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  if (error) return <p>Error :(</p>;
  return (
    <div className="container mx-auto ">
      <div className="px-6 pb-12">
      <Heading classes={`my-12`}>Loans</Heading>

      <div className=" flex flex-col lg:flex-row  gap-4 lg:gap-6 mb-6  mx-4">
        {LoanTypes.map((loanCategories) => (
          <div
            className={`font-sans px-3 py-1.5 lg:px-6 lg:py-1.5 rounded-3xl cursor-pointer shadow-lg ${
              loanCategories.attributes.typesid === activeTab
                ? activetab
                : nonactivetab
            }`}
            onClick={() => changeTab(loanCategories.attributes.typesid)}
          >
            {loanCategories.attributes.typesname}
          </div>
        ))}
      </div>

      <LoanCategory
        loanData={loanData?.length > 0 ? loanData : defaultData}
        activeCategoryTab={activeCategoryTab}
        setActiveCategoryTab={setActiveCategoryTab}
        categoryDescription={categoryDescription}
        setCategoryDescription={setCategoryDescription}
      />
      </div>
    </div>
  );
}
