import React from "react";
import { IoMdAttach } from "react-icons/io";
import { NavLink } from "react-router-dom";

const CovidReliefSiscoCustomers = () => {
  return (
    <div className="container mx-auto lg:my-20">
      <div className="lg:mx-20 lg:my-20 my-4 mx-4 font-sans">
        <h1 className="text-center my-20 font-medium text-2xl lg:text-5xl text-[#12381f]">
          COVID - 19 RELIEF FOR SISCO BANK CUSTOMERS Details
        </h1>
        <div className=" m-1 md:m-10 shadow-2xl">
          <div className="flex flex-col items-center">
            <span className=" font-semibold text-black text-md lg:text-2xl">
              COVID - 19 RELIEF FOR SISCO BANK CUSTOMERS
            </span>
            <span className="text-slate-400 text-sm lg:text-xl font-semibold">
              08 July 2020
            </span>
          </div>
          <br />
          <hr />
          <div className="flex flex-row justify-between gap-4 pt-20 pb-40 mx-10">
            <p className=" text-slate-600 lg:text-xl text-sm px-1 md:px-6 font-semibold">
              SISCO Bank announces the following relief measures for business
              concerns who have availed investment market loans/working capital
              loans from SISCO Bank and are experiencing capital inadequacy
              owing to the factors relating to COVID- 19. Units engaged in
              Service sector like hotels, travel agencies, transporters,
              educational/training institutes, restaurants and smaIl
              manufacturing/production/agro enterprise units etc are encouraged
              to avail this facility. Banks offer of relief would constitute
              additional sanction of term roan/working capital up to 25% of the
              previous sanction to meet the liquidity shortfall for business.
              Rephasement of the composite loan would also be considered in
              deserving cases. The facility would be extended to bank borrowers
              whose business have been adversely affected due to COVID-19
              pandemic. Borrowal account should not be defaulting or NPA
              classified as on 01/02/2020.Home Branch may be contacted by
              existing borrowers to avail the benefit. Also, existing depositors
              (non borrowers) of the bank wanting to make fresh borrowings for
              engagement in income generating ventures are also invited to the
              respective branches for appropriate credit counseling.
            </p>
            <a href="/error">
              <IoMdAttach color={"blue"} className="h-10 w-4 lg:w-8" />
            </a>
          </div>
        </div>
        <NavLink to="/archives/circulars_and_notifications">
          <button className="text-blue-600  px-3 py-1 md:px-6 md:py-2  min-w-44  lg:ml-4 lg:text-xl text-xs font-sans font-semibold">
            &lt; Back to List
          </button>
        </NavLink>
      </div>
    </div>
  );
};

export default CovidReliefSiscoCustomers;
