import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import ReactMarkdown from "react-markdown";

import useAxios from "../../hooks/useAxios";
import Heading from "../../Reuseable-Components/font/Heading";
import Paragraph from "../../Reuseable-Components/font/Paragraph";

//import pmjjby_pdf from "../../Assets/PMJJBY_Insurance.pdf";
//import pmsby_pdf from "../../Assets/PMSBY_Insurance.pdf";

const GET_INSURANCE_DETAILS = "api/insurance-details?populate=*";

const Insurance = () => {
  const [selectedInsuranceTab, setSelectedInsuranceTab] = useState(2);
  const { data, error, loading } = useAxios(GET_INSURANCE_DETAILS);

  const activeTab = "text-white bg-green-600";
  const inactiveTab = "text-green-800 bg-white font-semibold";

  useEffect(() => {
    let selectedId = +localStorage.getItem("selectedId");
    // console.log({ selectedId });

    setSelectedInsuranceTab(selectedId || 2);
  }, [loading]);

  // console.log("Insurance Data", data);

  if (loading)
    return (
      <div className="flex items-center justify-center text-green-500 my-10">
        <div
          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  if (error) return <p>Error :(</p>;

  // console.log("Selected Tab ID", selectedInsuranceTab);

  return (
    <div className="container mx-auto font-sans">
      <div className="">
        {/* <h1 className="text-center my-20 font-medium text-2xl lg:text-5xl text-[#12381f]">
          Insurance
        </h1> */}
        <Heading classes={`my-10`}>Insurance</Heading>
        {data.map((insurance) => (
          <>
            <button
              className={`px-2 cursor-pointer   text-xl rounded-lg py-2.5 ${
                insurance?.id === selectedInsuranceTab ? activeTab : inactiveTab
              }`}
              onClick={() => setSelectedInsuranceTab(insurance?.id)}
            >
              {insurance?.attributes?.title}
            </button>
          </>
        ))}
        <br />
        {data.map((insurance) => (
          <>
            {selectedInsuranceTab === insurance.id && (
              <div className=" m-1 lg:m-4 p-6 shadow-xl">
                <div className="flex flex-col items-center">
                  <img
                    src={
                      insurance?.attributes?.logo?.data?.attributes?.url
                    }
                    className="w-36 lg:w-80"
                  ></img>
                  <br />
                  <span className=" text-gray-800 lg:text-3xl text-lg mb-2 ">
                    {insurance?.attributes?.title}
                  </span>

                  <a
                    href={
                     
                      insurance?.attributes?.insurance_pdf?.data[0]?.attributes?.url
                    }
                    target={"_blank"}
                  >
                    <button className="text-white  bg-blue-600 shadow mt-6 px-6 py-2 rounded hover:bg-blue-800 min-w-44">
                      Download Form
                    </button>
                  </a>
                  <br />
                  {/* <span className="font-normal text-black lg:text-xl text-lg mb-2"> */}
                  <Paragraph classes={`mb-2`}>
                    <ReactMarkdown
                      children={insurance?.attributes?.description}
                      //When you are entering this description in the Strapi rich text editor, for new line:
                      //After the line, give a space of two lines and write '&nbsp;' and then again a space of two lines.
                    />
                    {/* </span> */}
                  </Paragraph>
                  <br />
                  <a href="/get_in_touch/request_for_service">
                    <button className="text-white   bg-[#22C55E]  rounded-lg px-6 py-2.5">
                      Enquire
                    </button>
                  </a>
                </div>
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default Insurance;
