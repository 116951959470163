import React from "react";

import { BsSearch } from "react-icons/bs";
import Heading from "../../Reuseable-Components/font/Heading";

import UnclaimedDepositForm from "../../Assets/Unclaimed_Deposit.pdf";
import UnclaimedDepositImg from "../../Assets/Unclaimed_Deposit.jpg";

const UnclaimedDeposit = () => {
  return (
    <>
      <Heading classes={"lg:py-10"}>Unclaimed Deposit Form</Heading>
      {/* <object
            className=""
            height="100%"
            width="100%"
            type="application/pdf"
            data={UnclaimedDepositForm}
          >
            <span className="px-2 py-1 bg-blue-00 text-white">
              Your browser does not support PDFs.
              <a href={UnclaimedDepositForm}>
                click here to download the PDF file.
              </a>
            </span>
          </object> */}
      <div className="border m-10">
        <div className="flex justify-center">
          <img
            src={UnclaimedDepositImg}
            alt="Unclaimed Deposit Image"
            height="10"
            width="1000"
          />
        </div>
      </div>

      <div className="flex justify-center mb-10">
        <a href={UnclaimedDepositForm} download={true}>
          <button className="text-white text-sm lg:text-lg bg-blue-600 shadow mt-1 lg:mt-4 px-2 py-1 lg:px-4 lg:py-2 rounded hover:bg-blue-800 ">
            Download
          </button>
        </a>
      </div>
    </>
  );
};

export default UnclaimedDeposit;
