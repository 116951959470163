import React, { useState } from "react";

import useAxios from "../../hooks/useAxios";

import ReactMarkdown from "react-markdown";

import Modal from "react-modal";

import nomination_individual from "../../Assets/Nomination_Paper_Individual.jpg";
import nomination_society from "../../Assets/Nomination_Paper_Society.jpg";
import Heading from "../../Reuseable-Components/font/Heading";
import GenericModal from "../../Reuseable-Components/modals/GenericModal";
import { IoMdClose } from "react-icons/io";
import vehicleJpg from "../../Assets/pdf/VEHICLE AUCTION.jpg";

const GET_MEDIA_NEWS_DETAILS = "api/media-tab-news-details?populate=*";

const AGM_21_Notice = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const { data, error, loading } = useAxios(GET_MEDIA_NEWS_DETAILS);

  if (loading)
    return (
      <div className="flex items-center justify-center text-green-500 my-10">
        <div
          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  if (error) return <p>Error :(</p>;
  // console.log("Media News data- ", data);
  // console.log(
  //   "pddf",
  //   data[0]?.attributes?.downloadable_files?.data[2]?.attributes?.url
  // );

  const sisco_logo = data[0]?.attributes?.news_logo?.data?.attributes?.url;

  const nomination_image =
    data[0]?.attributes?.news_image?.data?.attributes?.url;

  const agm_notice =
    data[0]?.attributes?.downloadable_files?.data[0]?.attributes?.url;
  const individual_nomination_pdf =
    data[0]?.attributes?.downloadable_files?.data[1]?.attributes?.url;
  const society_nomination_pdf =
    data[0]?.attributes?.downloadable_files?.data[2]?.attributes?.url;

  //Style for Modal which appears on clicking 'Enlarge above image' button
  const customStyles = {
    content: {
      height: "90%",
      width: "70%",
      top: "50%",
      left: "50%",
      right: "10%",
      bottom: "10%",
      marginRight: "0%",
      transform: "translate(-50%, -50%)",
    },
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="container mx-auto lg:my-20">
      <div className="lg:mx-20 lg:my-20 my-2 mx-2">
        {/* <h1 className="text-center my-20 font-medium text-md lg:text-4xl text-slate-500 pb-4">
          21st Annual General Body Meeting of SISCO Bank on 27th November.
        </h1> */}
        <Heading classes={`my-20`}>
          21st Annual General Body Meeting of SISCO Bank on 27th November.
        </Heading>
        <div className="m-1 lg:m-10 shadow-2xl overflow-x-auto">
          <div className=" flex flex-col items-center py-2">
            <span className=" font-sans text-black text-sm lg:text-2xl">
              {data[0].attributes.news_title}
            </span>
            <span className="text-slate-400 font-sans text-xs lg:text-xl">
              05 November 2020
            </span>
          </div>
          <hr />
          <br />
          <div className="grid md:grid-cols-3">
            <div className="grid md:col-span-2">
              <span className="text-center my-2 font-sans font-medium text-md underline lg:text-3xl text-slate-500 pb-4">
                {/* {data[0].attributes.news_title} */}
                21st AGM Notice
              </span>
              <div className="flex flex-col items-center mb-4 lg:mb-0">
                <img src={sisco_logo} className="w-48" />
                <div className="w-96 text-slate-600 text-sm  font-sans font-semibold lg:text-lg pl-4">
                  {data[0].attributes.news_logo_title}
                </div>
              </div>
              <div className="flex flex-start gap-4 lg:justify-between px-4 pb-3">
                <span className="text-slate-600 text-sm font-sans font-semibold lg:text-lg">
                  Ref. No. {data[0].attributes.ref_no}
                </span>
                {/* <span> Date: {data[0].attributes.date}</span> */}
                <span className="text-slate-600 text-sm lg:text-lg font-sans font-semibold">
                  Date: 03.11.2020
                </span>
              </div>
              {/* <span className="font-normal text-black lg:text-xl text-lg mb-2">
                <ReactMarkdown
                  children={data[0]?.attributes?.news_description}
                />
              </span> */}
              <div className="pt-2 mr-4 ml-8 flex flex-col items-center">
                <span className="text-slate-600 font-sans font-semibold text-md lg:text-lg">
                  NOTICE
                </span>
                <br />
                <p className="text-slate-600  font-sans  text-sm lg:text-lg">
                  In terms of Section 32(1) of the Sikkim Co-operative Societies
                  Act, 1978 read with Bye–Law No. 30 of the Bye Laws of the
                  Bank, it is hereby notified that the 21st Annual General Body
                  Meeting of the Sikkim State Co-operative Bank Limited (SISCO
                  Bank) will be held at the Hotel Rendezvous, Gangtok from
                  11.00AM on the 27th of November 2020 (Friday) to transact the
                  following business:
                </p>
                <br />
                <div className="mx-0 lg:mx-4 font-sans ">
                  <ol className="list-decimal text-slate-600 text-sm font-sans  lg:text-lg mx-2 lg:mx-20 pb-4">
                    <li>
                      To confirm the proceedings of the 20th Annual General Body
                      Meeting of the Bank which was held on 12th September 2019
                    </li>
                    <li>
                      To receive and consider the Annual Report of the Bank for
                      the year 2019-20.
                    </li>
                    <li>
                      To receive, consider and approve the Audited Balance
                      Sheet, Profit &amp; Loss Account and Auditor’s Report of
                      the Bank as on 31 March, 2020 for the financial year ended
                      31st March 2020.
                    </li>
                    <li>
                      To sanction appropriation of net profit to various
                      reserves for the year 2019-20, as recommended by the Board
                      of Directors of the Bank.
                    </li>
                    <li>
                      To consider and decide in the matter of declaration of
                      dividend for the year 2019-20, as recommended by the Board
                      of Directors of the Bank.
                    </li>
                    <li>
                      To consider and approve the Capital Budget of the Bank for
                      the year 2020-21 along with the actual vis-a-vis the
                      estimates for the F.Y. 2019-20.
                    </li>
                    <li>
                      To consider and approve the Business Budget of the Bank
                      for the year 2020-21 along-with the actual vis-a-vis the
                      estimates for the F.Y. 2019-20.
                    </li>
                    <li>
                      To consider and approve the Income and Expenditure Budget
                      of the bank for the year 2020-21, along-with the actual
                      vis-a-vis the estimates for the F.Y. 2019-20.
                    </li>
                    <li>
                      To fix up the maximum Borrowing power of the Bank for the
                      year 2020-21 in terms of the provision of the Bye Laws of
                      the Bank, The Sikkim Co-operative Societies Act 1978 as
                      amended from time to time and the Rules there under.
                    </li>
                    <li>
                      To consider reports of inspection and enquiry made in
                      accordance with the provisions of the Act, the Rules and
                      Bye Law, if any.
                    </li>
                    <li>
                      To consider matters relating to the loans and advances
                      given to the Directors and their relatives and actions to
                      be taken for recovery thereof in case of default.
                    </li>
                    <li>
                      Presentation of Awards to three best performing Multi
                      -Purpose Co-operative Societies for the year 2019-20.
                    </li>
                    <li>
                      To consider amendment in the bye laws of the Bank with
                      regards to the Shares and Share Capital of the Bank as per
                      amendment in the Banking Regulation Act(2020).
                    </li>
                    <li>
                      Election of the Directors of the Bank as per provisions of
                      the Sikkim Co-operative Societies Act 1978, as amended
                      from time to time, rules made there under, and Bye-laws of
                      the Bank.
                    </li>
                    <li>
                      Any other business for which prior notice has been given
                      at least 3 days in advance and / or with the permission of
                      the Chair.
                    </li>
                  </ol>
                </div>
                <div className="py-3 font-sans">
                  <p className="text-slate-600 text-sm  lg:text-lg">
                    All delegates and individual shareholders are requested to
                    make it convenient to attend the meeting and participate in
                    the deliberations.
                  </p>
                </div>
                <div className="py-3 font-sans">
                  <p className="text-slate-600 text-sm lg:text-lg">
                    It may please be noted that only one delegate from each
                    member Society can attend the meeting for which a supporting
                    resolution of the respective Managing Committee is to be
                    produced at the reception counter. The Identification Slip
                    as enclosed, duly filled in and signed by the accredited
                    authority may please be produced at the entrance of the
                    Meeting Hall between 10.15 a.m. and 10.45 a.m. of the event
                    date.
                  </p>
                </div>
                <div className="py-3 font-sans">
                  <p className="text-slate-600 text-sm lg:text-lg">
                    It may please be noted that “Rules” for conduct of elections
                    of the next Board of Directors of the Bank would be
                    available with the Deputy General Manager (Human Resource
                    Department) of the Bank from 11 a.m. to 5 p.m. on working
                    days at the Head Office premises of the Bank at Metro Point,
                    NH-10A , Tadong, East Sikkim, Pin-737102.
                  </p>
                </div>
                <div className="mt-2 mb-14 font-sans">
                  <span className="text-slate-600 text-sm lg:text-lg">
                    Sd/ Managing Director
                  </span>
                </div>
              </div>
            </div>
            <div className="grid md:col-span-1 mb-14">
              <div className=" mx-2 my-1 lg:mx-6 lg:my-0">
                <div className="text-gray-900 text-xs lg:text-xl space-y-5 shadow-lg p-4 bg-[#F5F5DC] h-full">
                  <p>
                    <div className="flex flex-col items-center ">
                      <span className="font-sans text-lg lg:text-2xl text-slate-500 pb-4">
                        Nomination Papers
                      </span>
                      <img src={nomination_image} className="w-96 pb-2" />

                      <div className="mb-24">
                        <button
                          className=" font-sans text-white text-sm lg:text-lg bg-blue-600 shadow mt-6 px-3 py-1 lg:px-6 lg:py-2 rounded hover:bg-blue-800 min-w-44"
                          onClick={openModal}
                        >
                          Enlarge above image
                        </button>
                      </div>
                      <hr />
                      <div className="overflow-hidden z-100">
                        {/* <Modal
                          isOpen={modalIsOpen}
                          //onAfterOpen={}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel="Nomination Form Modal"
                        >
                          <div
                            className="cursor-pointer hover:opacity-80 flex justify-end z-50"
                            onClick={closeModal}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6 text-black"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </div>
                          <div className="flex flex-col items-center">
                            <h2 className="font-sans text-lg lg:text-3xl text-slate-500 pb-4">
                              For Individual
                            </h2>
                            <img
                              src={nomination_individual}
                              className=" h-full lg:w-3/4 w-full border border-black"
                            />
                            <h2 className="font-sans text-lg lg:text-3xl text-slate-500 py-4">
                              For Society
                            </h2>
                            <img
                              src={nomination_society}
                              className=" h-full lg:w-3/4 w-full border border-black pb-4"
                            />
                          </div>
                        </Modal> */}

                        <GenericModal
                          open={modalIsOpen}
                          setOpen={setModalIsOpen}
                          title={""}
                          isLarge={true}
                          isAdd={false}
                        >
                          <div
                            className=" text-red-800 pb-3 float-right cursor-pointer"
                            onClick={() => setModalIsOpen(false)}
                          >
                            <IoMdClose size={28} />
                          </div>
                          <div className="z-100">
                            {/* <img
                              src={vehicleJpg}
                              className=" h-full w-full mx-auto border border-black"
                            /> */}
                            <div className="flex flex-col items-center">
                              <h2 className="font-sans text-lg lg:text-3xl text-slate-500 pb-4">
                                For Individual
                              </h2>
                              <img
                                src={nomination_individual}
                                className=" h-full lg:w-3/4 w-full border border-black"
                              />
                              <h2 className="font-sans text-lg lg:text-3xl text-slate-500 py-4">
                                For Society
                              </h2>
                              <img
                                src={nomination_society}
                                className=" h-full lg:w-3/4 w-full border border-black pb-4"
                              />
                            </div>
                          </div>
                        </GenericModal>
                      </div>
                    </div>
                    <hr />
                    <span className="font-sans text-xl lg:text-3xl text-slate-500 mt-6 pb-6 flex justify-center">
                      Downloadable Files
                    </span>
                    <div className="flex flex-col justify-start">
                      <a href={agm_notice} target="_blank">
                        <button className="text-white font-sans text-sm  bg-[#22C55E] hover:bg-green-600 rounded-lg px-3 py-1 lg:px-4 lg:py-2.5 mb-4">
                          Download 21st AGM Notice
                        </button>
                      </a>
                      <a href={individual_nomination_pdf} target="_blank">
                        <button className="text-white font-sans text-sm   bg-[#22C55E] hover:bg-green-600 rounded-lg px-3 py-1 lg:px-4 lg:py-2.5 mb-4">
                          Download Individual Nomination Form
                        </button>
                      </a>
                      <a href={society_nomination_pdf} target="_blank">
                        <button className="text-white font-sans text-sm   bg-[#22C55E] hover:bg-green-600 rounded-lg px-3 py-1 lg:px-4 lg:py-2.5 mb-4">
                          Download Society Nomination Form
                        </button>
                      </a>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ml-32  text-blue-600 font-sans text-sm lg:text-xl">
        <a href="/media/news_and_events">&lt; Back to List</a>
      </div>
    </div>
  );
};

export default AGM_21_Notice;
