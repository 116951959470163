import React from "react";
import { motion, AnimatePresence } from "framer-motion";

export const Accordion = ({ title, content, isOpen, onAccordionClick }) => {
  const itemVariants = {
    closed: {
      opacity: 0,
    },
    open: { opacity: 1 },
  };
  return (
    <div>
      <div className="my-4 mx-5 ">
        <div
          className="flex items-center justify-start gap-2 text-white cursor-pointer "
          onClick={onAccordionClick}
        >
          <motion.p className="font-semibold" variants={itemVariants}>
            {title}
          </motion.p>
          <motion.div
            className="transform rotate-0 transition-transform duration-300 ease-in-out"
            variants={itemVariants}
          >
            {isOpen ? (
              <>
                <svg
                  className={
                    "transform rotate-180  h-5 w-5 text-green group-hover:text-green-200 transition ease-out duration-200"
                  }
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </>
            ) : (
              <>
                <svg
                  className={
                    "transform rotate-0 transition ease-out duration-200  h-5 w-5 text-green group-hover:text-green-200"
                  }
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </>
            )}
          </motion.div>
        </div>

        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{
                opacity: 1,
                height: "auto",
                transition: { delay: 0.1, duration: 0.4 },
              }}
              exit={{
                opacity: 0,
                height: 0,
                transition: { duration: 0.2 },
              }}
              className="p-2"
            >
              {content}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};
