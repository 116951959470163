import React, { Fragment } from "react";

import AwardsCard from "../../Reuseable-Components/AwardsCard";
import useAxios from "../../hooks/useAxios";
import Heading from "../../Reuseable-Components/font/Heading";

const GET_AWARDS_AND_RECOGNITION = "api/awards?populate=*";

export default function AwardsAndRecognitions() {
  const { data, error, loading } = useAxios(GET_AWARDS_AND_RECOGNITION);

  if (loading)
    return (
      <div className="flex items-center justify-center text-green-500 my-10">
        <div
          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  if (error) return <p>Error :(</p>;
  // console.log("awards data", data);

  return (
    <Fragment>
      <section className=" pb-10 lg:pb-20 px-4 container mx-auto" id="awards-recognition">
        <div className="">
          <div className="flex flex-wrap justify-center">
            <div className="w-full px-4">

              <Heading classes={`my-10`}>
                Awards and Recognition
                <div className="w-10 border m-auto border-primary mt-1"></div>
              </Heading>
            </div>
          </div>

          <div className="grid lg:grid-cols-3 gap-8 md:px-20 lg:px-0 font-sans">
            {data?.map((award) => (
              <AwardsCard
                award_id={award.attributes?.id}
                award_year={award?.attributes?.award_year} 
                award_title={award?.attributes?.award_title}
                award_description={award?.attributes?.award_description}
                award_image={
                  award?.attributes?.award_image?.data?.attributes?.url
                }
              />
            ))}
          </div>
        </div>
      </section>
    </Fragment>
  );
}
