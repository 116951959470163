import React from "react";
import { GrLocation } from "react-icons/gr";
import { BsTelephone } from "react-icons/bs";
import { FaFax } from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md";
import Heading from "../../Reuseable-Components/font/Heading";

const Ombudsman = () => {
  return (
    <div className="contianer mx-auto font-sans  ">
      <div className="lg:mx-20  my-10 mx-10">
        <Heading>Ombudsman</Heading>
        {/* <h1 className="text-center my-20 font-medium text-2xl lg:text-5xl text-[#12381f]">
          Ombudsman
        </h1> */}

        <div className="grid lg:grid-cols-2 lg:gap-8 gap-4 ">
          <OmbudsmanCard title={" Banking Ombudsman Contact Details"}>
            <p className="font-sams text-gray-800 lg:text-2xl text-lg mb-2 font-semibold">
              Shri Hauzel Thangzamuan
            </p>
            <p className="flex flex-row gap-3 align-middle justify-start items-center text-gray-700 text-sm lg:text-lg">
              <GrLocation className="w-10 md:w-5" />
              C/O Reserve Bank of India, 15, Netaji Subash Road, Kolkata-700001{" "}
              <br /> STD Code: 033
            </p>
            <p className="flex flex-row gap-3 items-center mt-2 text-sm lg:text-lg">
              <BsTelephone />
              Tel no: 22310217/22133353
            </p>
            <p className="flex flex-row gap-3 items-center mt-2 text-sm lg:text-lg">
              <FaFax />
              Fax No: 22305899
            </p>
            <p className="flex flex-row gap-3 items-center mt-2 text-sm lg:text-lg">
              <MdAlternateEmail />
              Email: cms.bokolkata@rbi.org.in
            </p>
          </OmbudsmanCard>
          <OmbudsmanCard title={"बैंकिंग ओम्बड्समैन के नाम और पते"}>
            <p className="font-body text-gray-800 lg:text-2xl text-lg mb-2 ">
              <span className="font-semibold">श्रीमती मोनिशा चक्रवर्ती</span>
            </p>
            <p className="flex flex-row gap-3 align-middle justify-start items-center text-gray-700 text-sm lg:text-lg">
              <GrLocation className="w-10 md:w-5" />
              द्वारा भारतीय रिज़र्व बैंक, 15, नेताजी सुभाष रोड, कोलकाता-700 001{" "}
              <br />
              एसटीडी कोड : 033
            </p>
            <p className="flex flex-row gap-3 items-center mt-2 text-sm lg:text-lg">
              <BsTelephone />
              दूरभाष : 22310217/22133353
            </p>
            <p className="flex flex-row gap-3 items-center mt-2 text-sm lg:text-lg">
              <FaFax />
              फैक्स नं. 22305899
            </p>
            <p className="flex flex-row gap-3 items-center mt-2 text-sm lg:text-lg">
              <MdAlternateEmail />
              ई-मेल : cms.bokolkata@rbi.org.in
            </p>
          </OmbudsmanCard>
        </div>
      </div>
    </div>
  );
};

export default Ombudsman;

const OmbudsmanCard = (props) => {
  return (
    <div className="shadow-2xl p-6">
      <p className="bg-blue-500 px-4 py-2 text-white text-sm lg:text-xl font-medium rounded ">
        {props?.title}
      </p>
      <div className="flex flex-col px-4 mt-2 gap-1">{props?.children}</div>
    </div>
  );
};
