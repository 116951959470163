import React from "react";
import { IoMdAttach } from "react-icons/io";
import { NavLink } from "react-router-dom";

const ChargesSavingsAccount = () => {
  return (
    <div className="container mx-auto lg:my-20">
      <div className="lg:mx-20 lg:my-20 my-4 mx-4">
        <h1 className="text-center my-20 font-sans text-2xl lg:text-5xl text-[#12381f]">
          Charges for Savings Account Details
        </h1>
        <div className=" m-1 md:m-10 shadow-2xl">
          <div className="flex flex-col items-center">
            <span className=" font-sans font-semibold text-black text-md lg:text-2xl">
              Charges for Savings Account
            </span>
            <span className="text-slate-400 text-sm font-sans font-semibold lg:text-xl">
              02 April 2019
            </span>
          </div>
          <br />
          <hr />
          <div className="flex flex-row md:justify-between gap-4 pt-20 pb-40 mx-10">
            <p className=" text-slate-600 lg:text-xl text-sm px-1 md:px-6 font-sans font-semibold">
              SISCO Bank Charges for maintenance of Savings Account WEF
              02/04/2019
            </p>
            <a href="/error">
              <IoMdAttach color={"blue"} className="h-10 w-4 lg:w-8" />
            </a>
          </div>
        </div>
        <NavLink to="/archives/circulars_and_notifications">
          <button className="text-blue-600 px-3 py-1 md:px-6 md:py-2 min-w-44  lg:ml-4 lg:text-xl text-xs font-sans font- font-semibold">
            &lt; Back to List
          </button>
        </NavLink>
      </div>
    </div>
  );
};

export default ChargesSavingsAccount;
