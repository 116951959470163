import React, { useState } from "react";
import Heading from "../../Reuseable-Components/font/Heading";
import Paragraph from "../../Reuseable-Components/font/Paragraph";
import { InputFieldCalc } from "./InputFieldCalc";

export default function FD() {
  const [fdAmount, setFdAmount] = useState("");
  const [fdTenure, setFdTenure] = useState("");
  const [rateOfInterest, setRateOfInterest] = useState("");

  const [principal, setPrincipal] = useState(0);
  const [compoundIntrest, setCompoundIntrest] = useState(0);

  const handleReset = () => {
    setFdAmount("");
    setFdTenure("");
    setRateOfInterest("");
    setPrincipal("");
    setCompoundIntrest("");
  };

  const onChangeTenure = (val) => {
    setFdTenure(Number(val));
    let tenure = 0;
    if (parseFloat(val) !== 0) {
      const tenureString = val.toString();
      tenure = parseFloat(val);
      if (tenure < 1) {
        var tenureStringSplit = tenureString.split(".");
        var decimalNumber = parseFloat(tenureStringSplit[1]);
        if (decimalNumber == 1) {
          setRateOfInterest(5.5);
        } else if (decimalNumber >= 2 && decimalNumber <= 5) {
          setRateOfInterest(6.3);
        } else if (decimalNumber >= 6 && decimalNumber <= 11) {
          setRateOfInterest(6.5);
        } else if (decimalNumber == 12) {
          tenure = 1;
        } else if (decimalNumber > 12) {
          tenure = decimalNumber / 12;
        }
      }
      if (tenure >= 1 && tenure < 2) {
        setRateOfInterest(7);
      } else if (tenure >= 2 && tenure < 3) {
        setRateOfInterest(7.5);
      } else if (tenure >= 3 && tenure < 10) {
        setRateOfInterest(7.5);
      }
    }
  };

  const handleSubmit = (e) => {
    let CI, RI, Tenure;
    if (!Number.isInteger(fdTenure)) {
      let tenureString = parseFloat(fdTenure).toString();
      let tenureStringSplit = tenureString.split(".");
      let decimalPlace = tenureStringSplit[1].length;
      let denominator = 1;
      let i = 1;
      for (i = 1; i <= decimalPlace; i++) {
        denominator = denominator * 10;
      }
      let decimalNumber = parseFloat(tenureStringSplit[1]);
      let actualMonthinYear = parseFloat(decimalNumber / 12).toFixed(4);
      if (decimalNumber < 1) {
        actualMonthinYear = (actualMonthinYear * 10) / denominator;
      }
      let divider = parseFloat(decimalNumber) / parseFloat(denominator);
      Tenure =
        parseFloat(fdTenure) -
        parseFloat(divider) +
        parseFloat(actualMonthinYear);
      Tenure = Math.floor(parseFloat(Tenure) * 12);
      RI = parseFloat(rateOfInterest) / 12;
      RI = RI / 100;
     

      CI = parseInt(parseFloat(fdAmount) * (Math.pow(1 + RI, Tenure) - 1));
     
    } else {
     
      Tenure = parseFloat(fdTenure) * 12;
    
      RI = parseFloat(rateOfInterest) / 12;
      RI = RI / 100;
     
      CI = parseInt(parseFloat(fdAmount) * (Math.pow(1 + RI, Tenure) - 1));
    
    }
    const fdPrinciple = parseInt(fdAmount);
    const fdIntrest = CI;
    setPrincipal(fdPrinciple);
    setCompoundIntrest(fdIntrest);
  };

  return (
    <div className="container mx-auto font-sans">
      {/* <h1 className="text-center  font-medium text-2xl lg:text-5xl text-[#12381f] mb-12">
        FD Calculator
      </h1> */}
      <Heading classes={`my-12`}>FD Calculator</Heading>
      <Paragraph classes={`md:p-4 px-10`}>
        We are happy to offer you an easy-to-use and convenient online financial
        tool, the fixed deposit interest calculator. This FD calculator can be
        accessed anytime and anywhere by just visiting our website. Forget the
        hassles of complex and lengthy calculations, and just utilize our fixed
        deposit calculator to &nbsp;
        <span className="text-gray-500 font-semibold 2xl:text-2xl">
          calculate the amount that your fixed deposit will yield at the end of
          its maturity period.
        </span>
      </Paragraph>

      <div className="grid md:grid-cols-2 py-12 px-4">
        <div className="py-10 grid md:col-span-1 gap-y-6 shadow-2xl px-6  ">
          <form action="" className="  font-body flex flex-col gap-y-6  ">
            <InputFieldCalc
              label="Fixed Deposit Amount:"
              onChange={(e) => setFdAmount(e.target.value)}
              value={fdAmount}
              error="**Enter Fixed Deposit Amount"
            />

            <InputFieldCalc
              label=" Deposit Tenure (Years) *"
              onChange={(e) => onChangeTenure(e.target.value)}
              value={fdTenure}
              error="**Enter Tenure (Years)"
            />

            <InputFieldCalc
              label="Rate of Interest"
              value={rateOfInterest}
              error="** Enter the Rate of Interest"
            />

            <div className="flex space-x-2 justify-start lg:text-lg ">
              <button
                type="button"
                className="inline-block px-4 py-2  text-base bg-[#0E381D] hover:bg-[#228545] text-white font-sans rounded-md 2xl:text-xl"
                onClick={handleSubmit}
              >
                Calculate
              </button>

              <button
                type="button"
                className="inline-block px-6 py-2 bg-[#C79E16] hover:bg-[#eebd1b] text-white font-sans   rounded-md text-base 2xl:text-xl"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>

            <div className="flex items center justify-between font-sans font-semibold  pt-6 2xl:text-2xl ">
              <p>Invested amount</p>
              <p className="text-green-900">₹ {principal}</p>
            </div>
            <div className="flex items center justify-between font-sans font-semibold 2xl:text-2xl  ">
              <p>Estimated return</p>
              <p className="text-green-900">₹ {compoundIntrest}</p>
            </div>
            <div className="flex items center justify-between font-sans font-semibold 2xl:text-2xl ">
              <p>Maturity amount</p>
              <p className="text-green-900">₹ {principal + compoundIntrest}</p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
