import React from "react";
import Paragraph from "../../Reuseable-Components/font/Paragraph";
import SubHeading from "../../Reuseable-Components/font/SubHeading";

export default function WhatIsFinancialInclusion() {
  return (
    <div className="container mx-auto  py-4 lg:py-12 font-sans">


      <div className="px-6 lg-px-0">
      <SubHeading> What is Financial Inclusion ?</SubHeading>

      <Paragraph classes={`pt-2`}>
        Financial Inclusion is a process of making financial service (saving,
        payments, credit, and insurance) accessible and affordable to all
        section of the society.
      </Paragraph>

      <Paragraph classes={`pt-2`}>
        It primarily aims to include everybody in the society by giving them
        basic financial services without looking at a person’s income or
        savings. Financial inclusion chiefly focuses on providing reliable
        financial solutions to the economically underprivileged sections of the
        society without having any unfair treatment. It intends to provide
        financial solutions without any signs of inequality. It is also
        committed to being transparent while offering financial assistance
        without any hidden transactions or costs.
      </Paragraph>

      <Paragraph classes={"pt-2"}>
        Financial inclusion wants everybody in the society to be involved and
        participate in financial management judiciously. There are many poor
        households population in Sikkim that does not have any access to
        financial services. They are not aware of banks and their functions.
        Even if they are aware of banks, many of the poor people do not have the
        access to get services from banks.
      </Paragraph>

      <Paragraph classes={`pt-2`}>
        They may not meet minimum eligibility criteria laid by banks and hence,
        they will not be able to secure a bank’s services. Banks have
        requirements such as minimum income, minimum credit score, age criteria,
        and minimum years of work experience etc. A bank will provide a deposit
        or a loan to an applicant only if he or she meets these criteria. Many
        of the poor people may be unemployed without any previous employment
        record due to lack of education, lack of resources, lack of money, etc.
        These economically underprivileged people of the society may also not
        have proper documents to provide to the banks for verification of
        identity or income. Every bank has certain mandatory documents that need
        to be furnished during a loan application process or during a bank
        account creation process. Many of these people do not have knowledge
        about the importance of these documents. They also do not have access to
        apply for government-sanctioned documents.
      </Paragraph>

      <Paragraph classes={`pt-2`}>
        Financial inclusion aims to eliminate these barriers and provide
        economically priced financial services to the less fortunate sections of
        the society so that they can be financially independent without
        depending on charity or other means of getting funds that are actually
        not sustainable. Financial inclusion also intends to spread awareness
        about financial services and financial management among people of the
        society. Moreover, it wants to develop formal and systematic credit
        avenues for the economically underprivileged sections of the society.
        For several years, only the middle and high classes of the society
        procured formal types of credit. Poor people were forced to rely on
        unorganized and informal forms of credit. Many of them were uneducated
        and did not have basic knowledge about finance and hence, they got
        cheated by the greedy and rich people of the society. Several poor
        people have been exploited for years in the context of financial
        assistance.
      </Paragraph>

      <SubHeading classes="pt-3">Goal of Financial Inclusion:</SubHeading>

      <Paragraph classes={`pt-2 pl-9`}>
        <li>
          Financial inclusion intends to help people secure financial services
          and products at economical prices such as deposits, fund transfer
          services, loans, insurance, payment services, etc.
        </li>
        <li>
          It aims to establish proper financial institutions to cater to the
          needs of the poor people.
        </li>
        <li>
          Financial inclusion aims to build and maintain financial
          sustainability so that the less fortunate people have a certainty of
          funds which they struggle to have.
        </li>
        <li>
          Financial inclusion also intends to have numerous institutions that
          offer affordable financial assistance so that there is sufficient
          competition so that clients have a lot of options to choose from.
        </li>
        <li>
          Financial inclusion intends to increase awareness about the benefits
          of financial services among the economically underprivileged sections
          of the society.
        </li>
        <li>
          The process of financial inclusion works towards creating financial
          products that are suitable for the less fortunate people of the
          society.
        </li>
        <li>
          Financial inclusion intends to improve financial literacy and
          financial awareness in the state.
        </li>
        <li>
          Financial inclusion aims to bring in digital financial solutions for
          the economically underprivileged people of the State.
        </li>
        <li>
          It also intends to bring in mobile banking or financial services in
          order to reach the poorest people living in extremely remote areas of
          the state.
        </li>
        <li>
          It aims to provide tailor-made and custom-made financial solutions to
          poor people as per their individual financial conditions, household
          needs, preferences, and income levels.
        </li>
      </Paragraph>

      <SubHeading classes={`pt-3`}>
        What is the need for financial inclusion in Sikkim?
      </SubHeading>
      <Paragraph classes={`pt-2 pl-9`}>
        <li>
          <span className="text-gray-600 font-medium">Development:</span>
          &nbsp;Greater access to financial services = Increase in savings +
          Decrease in income inequality &amp; poverty + Increase in employment
          levels.
        </li>
        <li>
          <span className="text-gray-600 font-medium">Growth:</span>
          &nbsp;It encourages the habit to save, thus enhancing capital
          formation in the state and giving it an economic boost. Also, the
          availability of sufficient and transparent credit from formal banking
          institutions will promote the entrepreneurial spirit among the people
          = increase in productivity and prosperity in rural areas.
        </li>
        <li>
          <span className="text-gray-600 font-medium">Service delivery:</span>
          &nbsp;Direct cash transfers to beneficiary bank account rather than
          physical cash payments against subsidies will become possible = funds
          actually reach the targeted beneficiaries instead of being siphoned
          off along the way.
        </li>
      </Paragraph>
      </div>
    </div>
  );
}
