import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";

import useAxios from "../../hooks/useAxios";
import Heading from "../../Reuseable-Components/font/Heading";
import SearchBox from "../../Reuseable-Components/SearchBox";

const GET_MEDIA_NEWS_DETAILS = "api/media-tab-news-details?populate=*";

const NewsAndEventsMedia = () => {
  const [searchText, setSearchText] = useState("");

  const { data, error, loading } = useAxios(GET_MEDIA_NEWS_DETAILS);

  if (loading)
    return (
      <div className="flex items-center justify-center text-green-500 my-10">
        <div
          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  if (error) return <p>Error :(</p>;
  // console.log("Media News data- ", data);

  return (
    <div className=" container mx-auto my-10">
      <div className="">
        {/* <h1 className="text-center my-20 font-medium text-2xl lg:text-5xl text-[#12381f]">
          News &amp; Events
        </h1> */}
        <Heading classes={`my-6`}>News &amp; Events</Heading>
        <div class="px-6 md:px-9  flex flex-col">
          <SearchBox searchText={searchText} setSearchText={setSearchText} />
          <div class="overflow-x-auto ">
            <div class="py-2 inline-block min-w-full ">
              <div class="overflow-scroll">
                <table className="min-w-full bg-green-50">
                  <thead className=" text-center">
                    <tr>
                      <th
                        scope="col"
                        class="text-lg font-sans font-semibold text-gray-900 px-6 py-4 border-r border-l border-t border-black"
                      >
                        News Title
                      </th>
                      <th
                        scope="col"
                        class="text-lg font-sans font-semibold text-gray-900 px-6 py-4 border-r border-t border-black"
                      >
                        News Date
                      </th>
                      <th
                        scope="col"
                        class="text-lg font-sans font-semibold text-gray-900 px-6 py-4 border-r border-t border-black"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {searchText === "" ? (
                      <>
                        {data.map((news) => (
                          <tr>
                            <td class="text-lg text-gray-900  font-semibold font-sans px-6 py-4 whitespace-nowrap border-x border-y border-black">
                              {news?.attributes?.news_title}
                            </td>
                            <td class="text-lg text-gray-900 font-sans font-semibold px-6 py-4 whitespace-nowrap border-x border-y border-black">
                              {news?.attributes?.news_date}
                            </td>
                            <td class="text-lg font-sans  text-gray-900  px-6 py-4 whitespace-nowrap border-x border-y border-black">
                              <NavLink to="/media/news_and_events/21st_annual_general_body_meeting">
                                <button className=" font-sans text-white bg-blue-600 shadow mt-4 px-6 py-2 rounded hover:bg-blue-800 min-w-44">
                                  View Details
                                </button>
                              </NavLink>
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <>
                        {data.filter((searchednews) =>
                          searchednews.attributes?.news_title
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                        ).length > 0 ? (
                          <>
                            {data
                              .filter((searchednews) =>
                                searchednews.attributes?.news_title
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                              )
                              .map((news) => (
                                <tr>
                                  <td class="text-lg text-gray-900 font-sans px-6 py-4 whitespace-nowrap border-x border-y border-black">
                                    {news?.attributes?.news_title}
                                  </td>
                                  <td class="text-lg text-gray-900 font-sans px-6 py-4 whitespace-nowrap border-x border-y border-black">
                                    {news?.attributes?.news_date}
                                  </td>
                                  <td class="text-lg font-sans text-gray-900  px-6 py-4 whitespace-nowrap border-x border-y border-black">
                                    <NavLink to="/media/news_and_events/21st_annual_general_body_meeting">
                                      <button className="text-white bg-blue-600 shadow mt-4 px-6 py-2 rounded hover:bg-blue-800 min-w-44">
                                        View Details
                                      </button>
                                    </NavLink>
                                  </td>
                                </tr>
                              ))}
                          </>
                        ) : (
                          <tr className="text-red-600 mt-6 font-sans">
                            No records found!
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* ------------------------------------------------------TABLE END--------------------------------------------------------------- */}
      </div>
    </div>
  );
};

export default NewsAndEventsMedia;
