import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { NavLink } from "react-router-dom";

import useAxios from "../../hooks/useAxios";
import Paragraph from "../../Reuseable-Components/font/Paragraph";
const GET_ACCOUNTS_CATEGORY = "api/productcategories?populate=*";

export default function Accounts_category({
  accountData,
  activeCategoryTab,
  setActiveCategoryTab,
  categoryDescription,
  setCategoryDescription,
}) {
  const activetabcss =
    "bg-green-100 rounded-md   border-[#0E381D] border-b-2 text-green-600 2xl:text-lg font-medium";
  const nonactivetabcss = "text-gray-500 2xl:text-lg font-semibold";
  const { data, error, loading } = useAxios(GET_ACCOUNTS_CATEGORY);

  const accountCategory = data?.filter(
    (accountschemes) =>
      accountschemes?.attributes?.checkratestype?.data?.attributes?.typesid ===
      accountData[0].attributes?.typesid
  );

  // console.log("accountData11", accountData[0].attributes);

  const defaultActiveTab =
    accountCategory && accountCategory[0]?.attributes.categoryid;

  const defalutproductCategoryDescription =
    accountCategory &&
    accountCategory.filter(
      (category) => category.attributes.categoryid === defaultActiveTab
    )[0].attributes.categorydescription;

  const changeTab = (id) => {
    setActiveCategoryTab(id);
    const productCategoryDescription =
      accountCategory &&
      accountCategory.filter(
        (category) => category.attributes.categoryid === id
      )[0].attributes.categorydescription;
    setCategoryDescription(productCategoryDescription);
  };

  // console.log("account-category-data", accountCategory);
  // console.log("DefaultActiveTab", defaultActiveTab);

  if (loading)
    return (

      <div className="flex items-center justify-center text-green-500 my-10">
        <div
          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  if (error) return <p>Error :(</p>;

  return (
    <div className="mx-auto">
      {accountCategory?.length > 0 && (
        <div className="my-6 px-6 ">
          <div className="font-sans flex lg:flex-row lg:gap-4  flex-col">
            {accountCategory.map((category, index) => (
              <div
                key={index}
                className={`${
                  activeCategoryTab !== undefined
                    ? category.attributes.categoryid === activeCategoryTab
                      ? activetabcss
                      : nonactivetabcss
                    : category.attributes.categoryid === defaultActiveTab
                    ? activetabcss
                    : nonactivetabcss
                }
         cursor-pointer  p-2`}
                onClick={() => changeTab(category.attributes.categoryid)}
              >
                {category.attributes.categorytitle}
              </div>
            ))}
          </div>

          <div className="pt-3 ">
            {categoryDescription !== "" ? (
              <Paragraph>
                <ReactMarkdown>{categoryDescription}</ReactMarkdown>
              </Paragraph>
            ) : (
              <Paragraph>
                <ReactMarkdown>
                  {defalutproductCategoryDescription}
                </ReactMarkdown>
              </Paragraph>
            )}
          </div>
        </div>
      )}

      <div className=" text-base 2xl:text-xl font-sans  px-6 py-6">
        <button className="px-6 py-2 bg-[#C79E16] hover:bg-[#9d7d11] rounded-xl text-white">
          <NavLink to="/get_in_touch/request_for_service">Enquire</NavLink>
        </button>
      </div>
    </div>
  );
}
